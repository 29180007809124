import { AfterViewInit, Component, OnDestroy, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { AppointmentDetailsAuthComponent } from '../../appointment-details-auth/appointment-details-auth.component';
import { AuthService } from '../../core/auth-service';
import { NotificationService } from '../../services/notification.service';
import { TaskService } from '../../services/task.service';
import { AuthTaskSortTypes } from '../enums/auth-task-sort-types';
import { AuthTask } from '../models/auth-task.model';
import { BuyerService } from '../../services/buyer.service';
import { Regions } from '../models/region';
import { RegionArrays } from '../models/region-arrays';
import { RegionFilter } from '../models/region-filter';
import { RegionNames } from '../models/region-names';
import { Buyer } from '../../models/buyer.model';
import { AuthorizationRequestService } from '../../services/authorization-request.service';
import { PagerService } from '../../pager/services/pager.service';
import { TaskCounterService } from '../../services/task-counter.service';
import { CounterTypes } from '../../enums/counter-types';

@Component({
  selector: 'app-task-list-auth',
  templateUrl: './task-list-auth.component.html',
  styleUrls: ['./task-list-auth.component.scss']
})

export class TaskListAuthComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() buyerObjectAuth: Buyer;

  loading: boolean;
  sortOpened: boolean;
  filterOpened: boolean;
  mobileFilterOpened: boolean;
  mobileSortOpened: boolean;
  differentBuyer: boolean;
  modalOpened: boolean;
  filterActive: boolean;
  isAnyRegionSelected = false;
  tableHasData = false;
  hasMultipleRegionGroups = false;
  seeMore = false;

  currentTime: number;
  appointmentId: number;
  buyerExpLevel: number;
  pageCount: number;
  pageSize = 7;
  currentPage = 1;
  sortIndex: number;

  username: string;
  sortOrder: string;
  refreshDate: NodeJS.Timeout;

  regionFilter: RegionFilter = new RegionFilter;
  regionArrays: Array<RegionArrays> = new Array<RegionArrays>();
  regionNames: Array<RegionNames> = new Array<RegionNames>();
  authTasks: Array<AuthTask> = new Array<AuthTask>();
  allRegionsNames: Array<string> = new Array<string>();
  allRegionsDivisions: Array<string> = new Array<string>();
  allRegions: Array<Regions> = new Array<Regions>();

  itmRegionNames: Array<RegionNames>;
  allRegionsObject: Array<Regions>;
  filteredTasks: Array<AuthTask>;
  pagedAuthTasks: Array<AuthTask>;
  authTaskSent: AuthTask;

  destroy$ = new Subject();

  public authSortTypes = Object.values(AuthTaskSortTypes);
  setTimer: NodeJS.Timeout;

  constructor(
    private taskService: TaskService,
    private authService: AuthService,
    private notifications: NotificationService,
    private modalService: NgbModal,
    private authRequestService: AuthorizationRequestService,
    private pagerService: PagerService,
    private buyerService: BuyerService,
    private counterService: TaskCounterService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.username = this.authService.getUser()?.username.split('@')[0].toLowerCase();
    this.currentTime = Date.now();
    this.buyerObjectAuth = await this.buyerService.getCurrentBuyer();
    this.getRegionFilterForUsername();
    this.retrieveAuthTasks();
    const regionIds = this.getRegionsIds();
    this.getRegionsForUser(regionIds);

    this.pagerService.getPageNumber().pipe(
      takeUntil(this.destroy$),
      catchError(() => {
        return EMPTY;
      })
    )
      .subscribe((result) => {
        if (this.filteredTasks) {
          this.onPageChange(result);
        }
      });
  }

  ngAfterViewInit(): void {
    this.refreshDate = setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.refreshDate);
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  retrieveAuthTasks() {
    const regionIds = this.getRegionsIds();
    this.taskService.getAuthTasks$(regionIds).pipe(
      tap(result => {
        this.authTasks = result;
        this.filterTasks();
        this.checkForSelectedRegions();
        this.loading = false;
        this.counterService.update(CounterTypes.AUTHTASK);
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to retrieve auth tasks list`, err);
        this.loading = false;
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  getRegionsIds() {
    this.buyerExpLevel = this.buyerObjectAuth.buyerExperienceLevel.experienceLevelId;
    const employeeRegRoles = this.buyerObjectAuth.employee.employeeRegionRoles;
    const regionIds = new Array<string>();
    employeeRegRoles.forEach(element => {
      if (element.roleId === 6) {
        regionIds.push(element.regionId.toString());
      }
    });
    if (regionIds.length > 1) {
      this.hasMultipleRegionGroups = true;
    } else {
      this.hasMultipleRegionGroups = false;
    }
    return regionIds;
  }

  getRegionsForUser(regionIds) {
    this.taskService.getRegionsForUser$(regionIds).pipe(
      tap(result => {
        this.allRegionsObject = result;
        this.allRegionsObject.forEach(element => {
          this.allRegionsNames.push(element.regionName);
          this.allRegionsDivisions.push(element.divisionName);

          const filter = this.regionArrays.filter(el => {
            return el.divisionName === element.divisionName;
          });
          if (filter.length === 0) {
            const filterReg = this.allRegionsObject.filter(item => {
              return item.divisionName === element.divisionName;
            }).map(el => {
              return el.regionName;
            });
            const itmRegionNames = Array<RegionNames>();
            filterReg.forEach(elem => {
              itmRegionNames.push({ regionName: elem, selected: false });
            });
            const itm = {} as RegionArrays;
            itm.divisionName = element.divisionName;
            itm.regionNameArray = itmRegionNames;
            // Selects Regions From DB
            if (this.regionFilter.regions !== undefined) {
              const DBRegions = this.regionFilter.regions.split(',');
              DBRegions.forEach(DBRegion => {
                itm.regionNameArray.forEach(region => {
                  if (region.regionName === DBRegion) {
                    region.selected = true;
                  }
                });
              });

              //
              this.regionArrays.push(itm);
            } else {
              this.regionArrays.push(itm);
            }

          }
        });
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to get regions for user`, err);
        this.loading = false;
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  toggleFilter() {
    this.filterOpened = !this.filterOpened;
    if (this.filterOpened) {
      this.regionArrays = new Array<RegionArrays>();
      const regionIds = this.getRegionsIds();
      this.getRegionsForUser(regionIds);
    }
  }

  applyFilters() {
    let selectedRegions = new Array<string>();
    if (this.regionFilter.username === null || this.regionFilter.username === undefined) {

      selectedRegions = this.getSelectedRegions(selectedRegions);
      this.regionFilter = new RegionFilter;
      this.regionFilter.username = this.username;
      this.regionFilter.regions = selectedRegions.toString();

      this.taskService.addRegionFilter$(this.regionFilter).pipe(
        catchError(err => {
          this.notifications.dangerToast(`Failed to add RegionFilter`, err);
          this.loading = false;
          return EMPTY;
        }),
        take(1)
      ).subscribe();

      this.filterTasks();

    } else {
      selectedRegions = this.getSelectedRegions(selectedRegions);
      this.regionFilter.regions = selectedRegions.toString();

      this.taskService.updateRegionFilter$(this.regionFilter).pipe(
        catchError(err => {
          this.notifications.dangerToast(`Failed to update RegionFilter`, err);
          this.loading = false;
          return EMPTY;
        }),
        take(1)
      ).subscribe();

      this.filterTasks();

    }
  }

  getSelectedRegions(selectedRegions: Array<string>) { // Gets selected Regions From DB
    this.regionArrays.forEach(division => {
      division.regionNameArray.forEach(regionNameArray => {
        if (regionNameArray.selected === true) {
          selectedRegions.push(regionNameArray.regionName);
        }
      });
    });
    return selectedRegions;
  }

  getRegionFilterForUsername() {
    this.taskService.getRegionFilterForUsername$(this.username).pipe(
      tap(result => {
        if (result != null) {
          this.regionFilter = result;

          // Checks if the filter contains any regions that the user doesnt have access to anymore
          this.taskService.getAllRegions$().pipe(
            tap(resultAllRegions => {
              this.allRegions = resultAllRegions['regionSummaries'];
              const userRegionIds = this.getRegionsIds();
              const userRegionsNames = [];
              userRegionIds.forEach(userRegion => {
                this.allRegions.forEach(region => {
                  if (parseInt(userRegion, 10) === region.regionId) {
                    userRegionsNames.push(region.regionName);
                  }
                });
              });
              this.regionFilter.regions.split(',').forEach(regionFromRegionFilter => {
                if (!userRegionsNames.includes(regionFromRegionFilter)) {
                  const regionFilterArray = this.regionFilter.regions.split(',');
                  const elementIndex = regionFilterArray.indexOf(regionFromRegionFilter);
                  if (elementIndex > -1) {
                    regionFilterArray.splice(elementIndex, 1);
                  }
                  this.regionFilter.regions = regionFilterArray.toString();
                  this.taskService.updateRegionFilter$(this.regionFilter).pipe(
                    catchError(err => {
                      this.notifications.dangerToast(`Failed to update RegionFilter`, err);
                      this.loading = false;
                      return EMPTY;
                    }),
                    take(1)
                  ).subscribe();
                }
              });
            }),
            catchError(err => {
              this.notifications.dangerToast(`Failed to get all regions`, err);
              this.loading = false;
              return EMPTY;
            }),
            take(1)
          ).subscribe();
        }
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to get RegionFilter for username`, err);
        this.loading = false;
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  formatDateForTimeRequested(date: Date) {
    let currentHours = date.getHours().toString();
    if (parseInt(currentHours, 10) < 10) {
      currentHours = '0' + currentHours;
    }
    let currentMinutes = date.getMinutes().toString();
    if (parseInt(currentMinutes, 10) < 10) {
      currentMinutes = '0' + currentMinutes;
    }
    return currentHours + ':' + currentMinutes;
  }

  filterTasks() {
    let tasksArray = new Array<AuthTask>();

    this.authTasks.forEach(task => {
      if (task.risk === 'High') {
        task.riskValue = 2;
      }
      if (task.risk === 'Medium') {
        task.riskValue = 1;
      }
      if (task.risk === 'Low') {
        task.riskValue = 0;
      }

      const newDate = new Date(task.requested);
      task.timeRequiredString = this.formatDateForTimeRequested(newDate);
      task.buyersName = this.createNameFromUsername(task.buyersName);
      if (this.regionFilter.regions !== undefined) {
        this.regionFilter.regions.split(',').forEach(region => {
          if (region === task.region) {
            tasksArray.push(task);
          }
        });
      } else {
        tasksArray.push(task);
      }

    });

    if (this.regionFilter.regions === '' || this.regionFilter.regions === null || this.regionFilter.regions === undefined) {
      tasksArray = this.authTasks;
      this.isAnyRegionSelected = false;
    } else {
      this.isAnyRegionSelected = true;
    }

    this.filteredTasks = this.sortTasks(tasksArray);
    const taskCount = this.filteredTasks.length;
    this.pageCount = Math.ceil(taskCount / this.pageSize);

    if (this.currentPage > this.pageCount) {
      this.currentPage = 1;
    }
    this.pageTasks();
  }

  pageTasks() {
    if (this.pageCount === 1) {
      this.pagedAuthTasks = this.filteredTasks;
    } else {
      const start = (this.currentPage * this.pageSize) - this.pageSize;
      if (this.currentPage === this.pageCount) {
        this.pagedAuthTasks = this.filteredTasks.slice(start);
      } else {
        const end = this.currentPage * this.pageSize;
        this.pagedAuthTasks = this.filteredTasks.slice(start, end);
      }
    }
    if (this.pagedAuthTasks.length > 0 || this.pageCount > 1) {
      this.tableHasData = true;
    } else {
      this.tableHasData = false;
    }

  }

  sortTasks(tasks: Array<AuthTask>): Array<AuthTask> {
    if (this.sortOrder === AuthTaskSortTypes.AuthType) {
      tasks.sort((a, b) => this.compareByAuthType(a, b));
    } else if (this.sortOrder === AuthTaskSortTypes.BuyersName) {
      tasks.sort((a, b) => this.compareByBuyersName(a, b));
    } else if (this.sortOrder === AuthTaskSortTypes.NewestFirst) {
      tasks.sort((a, b) => this.compareByDateNewestFirst(a, b));
    } else if (this.sortOrder === AuthTaskSortTypes.OldestFirst) {
      tasks.sort((a, b) => this.compareByDateOldestFirst(a, b));
    } else if (this.sortOrder === AuthTaskSortTypes.Risk) {
      tasks.sort((a, b) => this.compareByRisk(a, b));
    }
    return tasks;
  }

  compareByAuthType(a: AuthTask, b: AuthTask): number {
    if (a.authType > b.authType) {
      return 1;
    } else if (b.authType > a.authType) {
      return -1;
    } else {
      return 0;
    }
  }

  compareByBuyersName(a: AuthTask, b: AuthTask): number {
    if (a.buyersName > b.buyersName) {
      return 1;
    } else if (b.buyersName > a.buyersName) {
      return -1;
    } else {
      return 0;
    }
  }

  compareByDateNewestFirst(a: AuthTask, b: AuthTask): number {
    if (a.requested < b.requested) {
      return 1;
    } else if (b.requested < a.requested) {
      return -1;
    } else {
      return 0;
    }
  }

  compareByDateOldestFirst(a: AuthTask, b: AuthTask): number {
    if (a.requested > b.requested) {
      return 1;
    } else if (b.requested > a.requested) {
      return -1;
    } else {
      return 0;
    }
  }

  compareByRisk(a: AuthTask, b: AuthTask): number {
    if (a.riskValue < b.riskValue) {
      return 1;
    } else if (b.riskValue < a.riskValue) {
      return -1;
    } else {
      return 0;
    }
  }

  onSortChange($event, i) {
    this.sortIndex = i;
    this.sortOrder = $event;
    this.sortTasks(this.filteredTasks);
    this.pageTasks();
  }


  onPageChange(page: number) {
    this.currentPage = page;
    this.pageTasks();
  }

  toggleSelectRegion(region, index) {
    const regionName = region.regionNameArray[index];
    if (regionName.selected === true) {
      regionName.selected = false;
    } else {
      regionName.selected = true;
    }
  }

  toggleSort() {
    this.sortOpened = !this.sortOpened;
  }

  toggleMobileSort() {
    this.mobileSortOpened = !this.mobileSortOpened;
  }

  toggleMobileFilter() {
    this.mobileFilterOpened = !this.mobileFilterOpened;
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.pageTasks();
    }
  }

  nextPage() {
    if (this.currentPage < this.pageCount) {
      this.currentPage += 1;
      this.pageTasks();
    }
  }

  actionAuthTask(task: AuthTask) {
    const modalRef = this.modalService.open(AppointmentDetailsAuthComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'xl'
    });

    if (task.claimed) {
      if (this.buyerObjectAuth.employee.userName === task.claimedBy) {
        this.differentBuyer = false;
      } else {
        this.differentBuyer = true;
      }
    } else {
      this.differentBuyer = false;
    }

    modalRef.componentInstance.appointmentId = task.appointmentId;
    modalRef.componentInstance.buyerName = this.buyerObjectAuth.employee.userName;
    modalRef.componentInstance.differentBuyer = this.differentBuyer;
    modalRef.componentInstance.authTask = task;
    modalRef.componentInstance.buyerExpLevel = this.buyerExpLevel;
    modalRef.result.then(() => {
      this.refreshTasks();
    }, () => { });
  }

  claimAuthTask(task: AuthTask) {
    this.authRequestService.getRequest$(task.authRequestId).pipe(
      tap((result) => {
        if (result.claimed === false || result.claimed === null) {
          task.claimed = true;
          task.claimedBy = this.buyerObjectAuth.employee.userName;
          this.authRequestService.updateAuthTask(task);
        } else {
          if (this.buyerObjectAuth.employee.userName === result.claimedBy) {
            task.claimed = false;
            task.claimedBy = '';
            this.authRequestService.updateAuthTask(task);
          }
        }
      }),
      catchError((err) => {
        this.notifications.dangerToast('Failed to get authorization request by id', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  expand(task: AuthTask) {
    task.expanded = !task.expanded;
  }

  openTaskDetails(task: AuthTask) {
    this.modalOpened = true;
    this.appointmentId = task.appointmentId;
    this.authTaskSent = task;
  }

  backToTasks() {
    this.modalOpened = false;
  }

  createNameFromUsername(string) {
    const str = string.split('.');
    let name = '';
    str.forEach(element => {
      element = element.charAt(0).toUpperCase() + element.slice(1);
      if (name !== '') {
        name = name + ' ' + element;
      } else {
        name = name + element;
      }
    });
    return name;
  }

  onTouchStart(task: AuthTask) {
    this.setTimer = setTimeout(() => {
      this.claimAuthTask(task);
      if (task.expanded) {
        this.expand(task);
      }
    }, 1000);
  }

  onTouchEnd() {
    clearTimeout(this.setTimer);
  }

  refreshTasks() {
    this.filteredTasks = [];
    this.pagedAuthTasks = [];
    this.retrieveAuthTasks();
  }

  expandFilterMobile(region: RegionArrays) {
    region.opened = !region.opened;
  }

  toggleSelectRegionMobile(region, index) {
    const regionName = region.regionNameArray[index];
    regionName.selected = !regionName.selected;
    this.applyFilters();
    this.checkForSelectedRegions();
  }

  checkForSelectedRegions() {
    let hasAnyRegionSelected = false;
    this.regionArrays.forEach(element => {
      element.selected = false;
      element.regionNameArray.forEach(region => {
        if (region.selected) {
          element.selected = true;
          hasAnyRegionSelected = true;
        }
      });
    });
    this.filterActive = hasAnyRegionSelected;
    }

    get canAuthorise() {
        return this.buyerObjectAuth.employee.authorizerRoleId > 0;
    }
}
