<div class="modal-header">
  <h4 class="modal-title text-center" *ngIf="isTaskAction">{{ taskType }} Task</h4>
  <h4 class="modal-title text-center" *ngIf="!isTaskAction">Appointment</h4>
  <div *ngIf="isShowingOutcomePicker && !hideActions">
    <div (click)='updateOutcome()' class="outcome-select">{{ outcomeLabel }}
    </div>
  </div>
  <img class="modal-close" (click)="dismissModal()" alt="Dismiss modal">
</div>
<div class="modal-body">
  <input type="text" style="display:none" ngbAutofocus />
  <div class="container">
    <div class="row">
      <div class="col-3 px-0 me-3 mb-3">
        <div class="white-fill rounded-edge obfuscated-playback">
          <div class="modal-data-label modal-data-top-left modal-data-top-right">
            Name
          </div>
          <div class="modal-data py-3">
            {{ appointment.customerName }}
          </div>
          <div class="modal-data-label">
            Source
          </div>
          <div class="modal-data py-3 modal-data-bottom-left modal-data-bottom-right">
            {{ appointment.source }}
          </div>

          <div class="call-button" *ngIf="appointment.customerPhone">
            <a class="btn btn-block pe-4 pt-3" href="tel:{{appointment.customerPhone}}">
              <img src="../../assets/icons/buttons/phone.png" alt="Phone">
              <div class="bold">Call Customer</div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-4 ps-0 pe-0 mx-3 mb-3">
        <div class="white-fill rounded-edge flex-col">
          <div class="modal-data-label modal-data-top-left modal-data-top-right">
            VRM
          </div>
          <div class="modal-data py-2 vrm-padding" [ngClass]="{'change-color': !hasHpiData}">
            {{ appointment.vrm }}
          </div>
          <div class="modal-data-label">
            Date & Time of Appointment
          </div>
          <div class="modal-data py-2 modal-data-bottom-left modal-data-bottom-right centered">
            {{ appointment.appointmentTime | date: 'dd/MM/yyyy' }}
            <br>
            {{ appointment.appointmentTime | date: 'HH:mm' }}
          </div>

          <div class="row mx-0 rounded-edge">
            <div class="col-6 modal-data-label modal-data-left-col modal-data-top-left">
              PP Expiry
            </div>
            <div class="col-6 modal-data-label modal-data-top-right">
              Valuation
            </div>
            <div class="col-6 modal-data modal-data-left-col single-text">
              {{ appointment.pricePromiseExpiryDate | date: 'dd/MM/yyyy'}}
            </div>
            <div class="col-6 modal-data single-text">
              {{ appointment.vehiclePriceOffered | currency : 'GBP' }}
            </div>
          </div>
          <div class="row py-2 mx-0 modal-data-label gap-table-background"></div>
          <div class="row mx-0">
            <div class="col modal-data-label  modal-data-left-col modal-data-title">
              Collision
            </div>
            <div class="col modal-data-label modal-data-left-col modal-data-title">
              Finance
            </div>
            <div class="col modal-data-label modal-data-left-col modal-data-title">
              Vcheck
            </div>
            <div class="col modal-data-label modal-data-left-col  modal-data-title">
              RKC
            </div>
            <div class="col modal-data-label  modal-data-title">
              Stolen
            </div>
            </div>
            <div class="row mx-0 rounded-edge block-height">
            <div class="col modal-data modal-data-left-col modal-data-bottom-left single-text">
              <img *ngIf="hasHpiData" [src]="collision" alt="" class="ticks-style">
              <img *ngIf="!hasHpiData" src="../../assets/images/No-HPI-Data.png" alt="">
            </div>
            <div class="col modal-data modal-data-left-col single-text">
              <img *ngIf="hasHpiData" [src]="finance" alt="" class="ticks-style">
              <img *ngIf="!hasHpiData" src="../../assets/images/No-HPI-Data.png" alt="">
            </div>
            <div class="col modal-data modal-data-left-col single-text">
              <img *ngIf="hasHpiData" [src]="hasSalvage" alt="" [ngClass]="{'ticks-style': appointment.hasSalvage, 'ticks-style-dash': !appointment.hasSalvage }">
              <img *ngIf="!hasHpiData" src="../../assets/images/No-HPI-Data.png" alt="">
            </div>
            <div class="col modal-data modal-data-left-col single-text">
              <img *ngIf="hasHpiData" [src]="rkc" alt="" class="ticks-style">
              <img *ngIf="!hasHpiData" src="../../assets/images/No-HPI-Data.png" alt="">
            </div>
            <div class="col modal-data modal-data-bottom-right single-text">
              <img *ngIf="hasHpiData" [src]="stolen" alt="" class="ticks-style">
              <img *ngIf="!hasHpiData" src="../../assets/images/No-HPI-Data.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="col px-0 ms-3 mb-3">
        <div class="white-fill rounded-edge flex-col">
          <div class="row mx-0 rounded-edge">
            <div class="col-6 modal-data-label modal-data-left-col modal-data-top-left">
              CAP ID
            </div>
            <div class="col-6 modal-data-label modal-data-top-right">
              Mileage
            </div>
            <div class="col-6 modal-data modal-data-left-col single-text col-size">
              {{ appointment.capId }}
            </div>
            <div class="col-6 modal-data single-text col-size">
              {{ appointment.mileage | number }}
            </div>
            <div class="col-6 modal-data-label modal-data-left-col">
              Make
            </div>
            <div class="col-6 modal-data-label">
              Model
            </div>
            <div class="col-6 modal-data modal-data-left-col double-text col-size make-model">
              {{ appointment.make }}
            </div>
            <div class="col-6 modal-data double-text col-size make-model">
              {{ appointment.model }} - {{ appointment.derivative }}
            </div>
            <div class="col-6 modal-data-label modal-data-left-col">
              Engine
            </div>
            <div class="col-6 modal-data-label">
              Colour
            </div>
            <div class="col-6 modal-data modal-data-left-col single-text modal-data-bottom-left col-size">
              {{ engineFormat }}
            </div>
            <div class="col-6 modal-data single-text modal-data-bottom-right col-size">
              {{ appointment.colour }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 pad-top pad-bottom row mx-0 appointment-history rounded-edge">
        <div class="col modal-p1">
          Notes
        </div>
        <div class="col-auto modal-p1">
          <button *ngIf="isAddingNote" class="btn note-btn" [disabled]="isSavingNote"
            (click)="cancelNote()">Cancel</button>
          <button *ngIf="isAddingNote" class="btn note-btn small-action-btn" [ngClass]="{'loading': isSavingNote}"
            (click)="saveNote()">Save</button>
        </div>
        <div class="col-12 appointment-history-table">
          <textarea #noteInputBox class="note-input" width="100%" [formControl]="note" placeholder="Add a comment here"
            (input)="onNoteInput()"></textarea>
          <div *ngFor="let note of appointment.notes" class="note-box obfuscated-playback">
            <div class="note-stamp">
              {{ note.created | date: 'dd/MM/yyyy' }} | {{ note.created | date: 'HH:mm' }} | {{ note.username }}
              <span *ngIf="note.new" class="note-saved">
                <img src="../../assets/icons/appointment/saved.png" alt="">
                Saved
              </span>
            </div>
            <div class="note-text" [innerHTML]="formatForDisplay(note.text)"></div>
          </div>
        </div>
        <div class="col-12 pad-bottom"></div>
      </div>
    </div>
    <div class="row" *ngIf="!loading && !hideActions">
      <div *ngIf="(isNoShowTask || isConfirmCallTask || appointment.canReschedule) && isAssignedToThisBuyer" [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4'" class="modal-button-right pad-top">
        <button class="btn btn-block btn-lg modal-button button_clear"
          [ngClass]="{'loading': isRescheduling}" (click)="rescheduleAppointment()">
          <span class="reschedule-button-icon"></span>
          Reschedule
        </button>
      </div>
      <div *ngIf="!isVehiclePurchased && !isCustomerContactTask && !isNoShowTask && !isNotPurchasedTask"  [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4'"></div>
      <div *ngIf="isVehiclePurchased || isCustomerContactTask" [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4 offset-2'"
        class="modal-button-left modal-button-right pad-top">
        <button class="btn btn-block btn-lg modal-button turn-green"
          [ngClass]="isVehiclePurchased || isCustomerContactTask ? 'upload-btns': ''" (click)="uploadDocuments()">
          <span class="upload-documents-button-icon"></span>
          Upload Mandatory Images
        </button>
      </div>
      <div *ngIf="isVehiclePurchased || isCustomerContactTask" [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4'"
        class="modal-button-left modal-button-right pad-top">
        <button class="btn btn-block btn-lg modal-button turn-green"
          [ngClass]="isVehiclePurchased || isCustomerContactTask ? 'upload-btns': ''" (click)="uploadVehicleImages()">
          <span class="upload-vehicle-button-icon"></span>
          Add Vehicle Images
        </button>
      </div>

      <div *ngIf="isNotPurchasedTask" [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4'"
        class="modal-button-left modal-button-right pad-top">
        <button class="btn btn-block btn-lg modal-button button_clear"
          [ngClass]="{'loading': isRescheduling}" (click)="walkIn(false)">
          <span class="reschedule-button-icon"></span>
          Book Appointment
        </button>
      </div>
      <div *ngIf="!isVehiclePurchased && !isTaskAction && isAssignedToThisBuyer" [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4'"
        class="modal-button-right pr-0 pad-top">
        <button class="btn btn-block btn-lg modal-button"
          [disabled]="!isPurchasable"
          [ngClass]="!isPurchasable ? 'button_grey' : 'button_green'" (click)="continuePurchase()">
          <span [ngClass]="!isPurchasable ? 'next-button-icon-grey' : 'next-button-icon'"></span>
          {{ appointment.isStarted ? 'Continue Purchase' : 'Start Purchase' }}
        </button>
      </div>
      <div *ngIf="!isAssignedToThisBuyer && !isVehiclePurchased && !isTaskAction" [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4'"
        class="modal-button-right pr-0 pad-top">
        <button class="btn btn-block btn-lg modal-button button_appointment"  [disabled]="appointment.isStarted"  [ngClass]="{'button_grey' : appointment.isStarted}"
          (click)="claimAppointment()">
          <span class="claim-appointment"></span>
           Claim  Appointment
        </button>
      </div>
      <div *ngIf="(!isVehiclePurchased && isTaskAction) || isCustomerContactTask"  [ngClass]="isCustomerContactTask ? 'col-3' : 'col-4'"
        class="modal-button-right pad-top">
        <button
          class="btn btn-block btn-lg modal-button button_green" [ngClass]="{'loading': isCompleting}"
          (click)="completeTask()">
          <span class="complete-button-icon"></span>
          Complete Task
        </button>
      </div>
    </div>
    <div class="col-12 pad-bottom"></div>
  </div>
</div>

<ng-container *ngIf="loading">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</ng-container>