import { Component, Input, OnInit } from '@angular/core';
import { tap, catchError, take } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { InvoiceService } from '../services/invoice.service';
import { EMPTY } from 'rxjs';
import { InvoiceContent } from '../invoice/models/invoiceContent.model';
import { Signature } from './models/signature.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignatureInputComponent } from '../signature-input/signature-input.component';
import { UntypedFormGroup } from '@angular/forms';
import { PathFinderService } from '../services/path-finder.service';
import { LoadingService } from '../services/loading.service';
import { InvoiceSignatureInputComponent } from '../signature-input/invoice-signature-input.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html'
})
export class InvoiceComponent implements OnInit {

  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Input() set canLoadContent(canLoad: boolean) {
    this.isDataReady = canLoad ?? true;
    this.retrieveInvoiceData();
  }

  loading: boolean;
  today: Date;
  isDataReady: boolean;
  isInitialised: boolean;

  purchaseDetails: InvoiceContent;
  customerSignature: Signature;
  buyerSignature: Signature;

  constructor(private modalService: NgbModal,
    private notifications: NotificationService,
    private invoiceService: InvoiceService,
    private loadingService: LoadingService,
    private pathFinder: PathFinderService) {
  }

  ngOnInit() {
    this.today = new Date();
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.isInitialised = true;
    this.retrieveInvoiceData();
    this.pathFinder.registerTransitionEvent((onComplete => {
      const signatures = [this.customerSignature, this.buyerSignature];
      this.invoiceService.generateInvoice$(this.quoteStateId, signatures).pipe(
        tap(() => {
          onComplete(true);
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to generate invoice', err);
          onComplete(false);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }));
  }

  retrieveInvoiceData() {
    if (this.isInitialised && this.isDataReady) {
      this.invoiceService.getInvoiceDetails$(this.quoteStateId).pipe(
        tap(result => {
          this.purchaseDetails = result;
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
        }),
        catchError(err => {
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
          this.notifications.dangerToast('Failed to retrieve invoice details', err);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }

  signatureAdded() {
    if (this.isInvoiceComplete()) {
      this.group.controls.value.setValue(true);
    }
  }

  isInvoiceComplete() {
    return this.buyerSignature && this.customerSignature;
  }

  customerSign() {
    const modalRef = this.modalService.open(InvoiceSignatureInputComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'xl',
      windowClass: 'signatureWindow'
    });
    modalRef.componentInstance.quoteStateId = this.quoteStateId;
    modalRef.componentInstance.purchaseDetails = this.purchaseDetails.purchaseDetails;
    modalRef.result.then(result => {
      this.customerSignature = result;
      this.signatureAdded();
    }, () => {});
  }

  buyerSign() {
    const modalRef = this.modalService.open(SignatureInputComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'lg',
      windowClass: 'signatureWindow'
    });
    modalRef.componentInstance.isWbacSignature = true;
    modalRef.componentInstance.quoteStateId = this.quoteStateId;
    modalRef.componentInstance.disclaimer = 'Authorised for and on behalf of WE BUY ANY CAR Limited';
    modalRef.result.then(result => {
      this.buyerSignature = result;
      this.signatureAdded();
    }, () => {});
  }

}
