import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DamageService } from '../../services/damage.service';
import { ValuationSummary } from '../models/valuation-summary.model';
import { Fault } from '../models/fault.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { ValuationService } from '../../services/valuation.service';
import { BaseComponentDirective } from '../../base/base.component';
import { tap, takeUntil, catchError, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Buyer } from '../../models/buyer.model';
import { BuyerService } from '../../services/buyer.service';
import { AuthorizationTypes } from '../../authorization/enums/authorization-types';
import { FlexTypes } from '../../enums/flex-types';
import { PathFinderService } from '../../services/path-finder.service';
import { LoadingService } from '../../services/loading.service';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-damage-cost',
  templateUrl: './damage-cost.component.html',
  styleUrls: ['../damage.scss']
})
export class DamageCostComponent extends BaseComponentDirective implements OnInit {

  @Input() group: UntypedFormGroup;
  @Input() quoteStateId: number;
  @Input() questionForm: UntypedFormGroup;
  @Output() change = new EventEmitter<UntypedFormGroup>();

  damageMatrix: Fault[];
  valuation: ValuationSummary;
  loading: boolean;
  recalculating: boolean;
  needsRecalculation: boolean;
  damageGroup: UntypedFormGroup;
  nextId = 0;

  buyer: Buyer;

  canFlex: boolean;
  isFlexing: boolean;
  isMaxFlexExceeded: boolean;
  totalFlexAmountControl: UntypedFormGroup;
  flexAuthBandControl: UntypedFormGroup;

  isPricePromiseVoid: boolean;
  pricePromiseVoidReason: string;
  customerDamageItems: Fault[];
  source: string;
  hasDamages: boolean;

  currentTab = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private damageService: DamageService,
    private valuationService: ValuationService,
    private buyerService: BuyerService,
    private notifications: NotificationService,
    private pathFinder: PathFinderService,
    private loadingService: LoadingService,
    private loggingService: LoggingService
  ) {
    super();
  }

  async ngOnInit() {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.recalculating = false;
    this.isFlexing = false;
    this.needsRecalculation = false;

    this.totalFlexAmountControl = this.getFormControl('TotalFlexAmount');
    this.flexAuthBandControl = this.getFormControl('FlexAuthorizationBand');

    this.buyer = await this.buyerService.getCurrentBuyer();

    this.damageService.getDamageMatrix$().pipe(
      tap(result => {
        this.damageMatrix = result;
        this.loadValuation();
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to retrieve damage', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  getFormControl(parameterName: string): UntypedFormGroup {
    return this.questionForm.get('items')['controls'].filter(x => x.value.name === parameterName)[0];
  }

  loadValuation() {
    this.valuationService.getValuation$(this.quoteStateId).pipe(
      tap(result => {
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
        this.valuation = result;
        this.customerDamageItems = result.customerDamage;
        this.hasDamages = result.customerDamage.length > 0;
        this.source = result.source;

        this.isPricePromiseVoid = this.valuation.isPricePromiseVoid;
        this.pricePromiseVoidReason = this.valuation.pricePromiseVoidReason;

        const flexDamage = this.valuation.damage.find(function (element) {
          return element.flexAmount && element.flexAmount > 0;
        });
        if (flexDamage) {
          this.isFlexing = true;
        }

        this.damageGroup = this.formBuilder.group({
          name: 'damage',
          damageItems: this.formBuilder.array([])
        });

        this.damageGroup.controls.damageItems.valueChanges.pipe(
          tap(() => {
            this.flexChanged();
          }),
          takeUntil(this.componentDestroyed))
          .subscribe();

        this.setDamageArray();
        this.setQuestionValue(true);
        this.needsRecalculation = false;

        this.totalFlexAmountControl.controls.value.setValue(this.totalFlexAmount);
        this.checkAuthorizationBand();
        this.checkVehicleCondition(result);

        if (!this.buyer
          || this.buyer.flexType === FlexTypes.NotAllowed
          || !this.valuation.damage.some(d => d.canFlex)
          || this.noFlexBands(this.valuation)) {
          this.canFlex = false;
          this.loggingService.logInfo(`QuoteState ${this.quoteStateId} - Flex not allowed:  ${this.cannotFlexReason()}`);
        } else {
          this.canFlex = true;
        }
      }),
      catchError(err => {
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
        this.notifications.dangerToast('Failed to retrieve valuation', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  noFlexBands(valuation: ValuationSummary): boolean {
    return valuation.maxFlex === 0 && valuation.flexBand1 === 0 && valuation.flexBand2 === 0 && valuation.superFlex === 0;
  }

  cannotFlexReason(): string {
    const reasons: string[] = [];
    if (!this.buyer) {
      reasons.push('No buyer');
    } else if (this.buyer.flexType === FlexTypes.NotAllowed) {
      reasons.push(`Buyer ${this.buyer.employeeId} is not allowed to flex`);
    }
    if (!this.valuation.damage.some(d => d.canFlex)) {
      reasons.push(`Valuation ${this.valuation.valuationId} has no flexable damage items`);
    }
    if (this.noFlexBands(this.valuation)) {
      reasons.push(`Valuation ${this.valuation.valuationId} has no flex bands`);
    }

    return reasons.join(', ');
  }

  setQuestionValue(formValid: boolean) {
    if (formValid) {
      this.group.patchValue({ value: formValid });
    } else {
      this.group.patchValue({ value: null });
    }
    this.change.emit(this.group);
  }

  setDamageArray() {
    const damageArray = this.damageGroup.get('damageItems') as UntypedFormArray;

    while (damageArray.length !== 0) {
      damageArray.removeAt(0);
    }

    this.valuation.damage.forEach(element => {
      damageArray.push(this.createDamageGroup(element));
    });
  }

  createDamageGroup(fault: Fault) {
    fault.uiId = `d${++this.nextId}`;

    const damage = this.damageMatrix.find(function (element) {
      return element.zoneId === fault.zoneId && element.componentId === fault.componentId && element.faultId === fault.faultId;
    });

    fault.canFlex = damage?.canFlex ? damage.canFlex : false;

    const formGroup = this.formBuilder.group({
      uiId: fault.uiId,
      zoneId: fault.zoneId,
      zoneName: fault.zoneName,
      componentId: fault.componentId,
      componentName: fault.componentName,
      faultId: fault.faultId,
      faultName: fault.faultName,
      cost: fault.cost,
      flex: fault.flexAmount ? fault.flexAmount : 0,
      canFlex: damage?.canFlex ? damage.canFlex : false
    });

    formGroup.controls.flex.setValidators([
      Validators.required, Validators.max(fault.cost), Validators.pattern('^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$')
    ]);

    return formGroup;
  }

  trackByFn(index, item) {
    return item.uiId;
  }

  confirmEntry(event) {
    event.target.blur();
  }

  checkZero(event) {
    const value = event.srcElement.value;
    if (value === null || value === '') {
      event.srcElement.value = '0';
      event.srcElement.dispatchEvent(new Event('input'));
    }
  }

  flexChanged() {
    this.needsRecalculation = true;
    this.setQuestionValue(false);
  }

  hasDamage() {
    return this.valuation?.damage && this.valuation.damage.length > 0;
  }

  isWebSource(): boolean {
    return this.source === 'WBAC' || this.source === 'WBAV';
  }

  flexIt() {
    if (this.currentTab === 1) {
      this.currentTab = 0;
    }
    this.isFlexing = true;
  }

  recalculate() {
    if (this.recalculating) {
      return;
    }
    this.damageGroup.value.damageItems.forEach(damage => {
      const fault = this.valuation.damage.find(function (element) {
        return element.uiId === damage.uiId;
      });
      if (fault) {
        fault.flexAmount = damage.flex.toString().replace(',', '.');
      }
    });

    this.recalculating = true;
    this.damageService.postDamage$(this.quoteStateId, this.valuation.damage).pipe(
      tap(result => {
        this.valuation = result;
        this.pathFinder.setValuationAmount(result);
        this.setDamageArray();

        this.recalculating = false;
        this.needsRecalculation = false;
        this.setQuestionValue(true);

        this.totalFlexAmountControl.controls.value.setValue(this.totalFlexAmount);
        this.checkAuthorizationBand();
        this.checkVehicleCondition(result);
      }),
      catchError(err => {
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
        this.notifications.dangerToast('Failed to recalculate', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  checkAuthorizationBand() {
    this.valuation.flexAuthRequired = false;
    this.isMaxFlexExceeded = false;

    if (this.totalFlexAmount === 0) {
      this.flexAuthBandControl.controls.value.setValue(null);
      return;
    }

    const lowValueTradeFlex = Math.min(this.valuation.maxFlex, this.buyer.flexAmount);

    if (this.totalFlexAmount > Math.max(lowValueTradeFlex, this.valuation.flexBand1, this.valuation.flexBand2, this.valuation.superFlex)) {
      this.flexAuthBandControl.controls.value.setValue(null);
      this.totalFlexAmountControl.setErrors({ maxFlexExceeded: true });
      this.isMaxFlexExceeded = true;
      return;
    }

    if (this.totalFlexAmount <= this.valuation.flexBand1) {
      // Autonomous and within buyers limit
      if (this.buyer.flexType === FlexTypes.CompleteAutonomy && this.totalFlexAmount <= this.buyer.flexAmount) {
        this.flexAuthBandControl.controls.value.setValue(null);
      } else {
        this.valuation.flexAuthRequired = true;
        this.flexAuthBandControl.controls.value.setValue(AuthorizationTypes.FlexBand1);
      }
      return;
    }

    if (this.totalFlexAmount <= this.valuation.flexBand2) {
      this.valuation.flexAuthRequired = true;
      this.flexAuthBandControl.controls.value.setValue(AuthorizationTypes.FlexBand2);
      return;
    }

    if (this.totalFlexAmount <= this.valuation.superFlex) {
      this.valuation.flexAuthRequired = true;
      this.flexAuthBandControl.controls.value.setValue(AuthorizationTypes.SuperFlex);
      return;
    }

    if (this.totalFlexAmount <= lowValueTradeFlex) {
      this.flexAuthBandControl.controls.value.setValue(null);
    }
  }

  checkVehicleCondition(valuation: ValuationSummary) {
    const offerPriceThreshold = this.pathFinder.getPurchaseSettingAsNumber('OfferPrice');
    const mileageThreshold = this.pathFinder.getPurchaseSettingAsNumber('Mileage');
    const ravPercent = this.pathFinder.getPurchaseSettingAsNumber('RAVPercent');
    const ravMaxDamage = this.pathFinder.getPurchaseSettingAsNumber('RavMaxDamage');
    const vehicleAgeThreshold = this.pathFinder.getPurchaseSettingAsNumber('VehicleAgeInDays');

    // Check adjusted damage
    if (valuation.originalVehiclePriceOffered >= offerPriceThreshold) {
      const mileage = Number(valuation.recordedMileage);
      const damage = valuation.totalDamageCost;
      const adjustedDamage = valuation.totalDamageCost - valuation.totalFlexAmount;
      const adjustedDamagePercent = adjustedDamage / valuation.originalVehiclePriceOffered * 100;

      if (mileage && mileage >= mileageThreshold && adjustedDamagePercent <= ravPercent && damage <= ravMaxDamage) {
        this.pathFinder.appendAnswer('VehicleConditionAuthorization', 'true');
        return;
      }
    }

    // Check age and condition
    if (valuation.registrationDate) {
      const currentDate = new Date();
      const registrationDate = new Date(valuation.registrationDate);
      const ageInDays = Math.floor((currentDate.getTime() - registrationDate.getTime()) / 86400000);
      if (valuation.grade === '1' && ageInDays > vehicleAgeThreshold) {
        this.pathFinder.appendAnswer('VehicleConditionAuthorization', 'true');
        return;
      }
    }
  }

  hasValidPricePromise(): boolean {
    return this.valuation.pricePromise && this.valuation.pricePromise > 0 && !this.isPricePromiseVoid;
  }

  get totalDamageCost() {
    return this.valuation.totalDamageCost ? this.valuation.totalDamageCost : 0;
  }

  get totalFlexAmount() {
    return this.valuation.totalFlexAmount ? this.valuation.totalFlexAmount : 0;
  }

  get totalAppraisalValue() {
    return this.totalDamageCost - this.totalFlexAmount;
  }
}
