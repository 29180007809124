import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from './models/question.model';
import { QuestionTransition } from './models/question-transition.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, fromEvent, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { AppointmentValuationDetails } from '../appointment-valuation-details/models/appointment-valuation-details';
import { AppointmentValuationDetailsService } from '../appointment-valuation-details/services/appointment-valuation-details.service';
import { CancellationPurchaseComponent } from '../cancellation-purchase/cancellation-purchase.component';
import { ScreenControlTypes } from '../enums/screen-control-types';
import { KeyValuePair } from '../models/key-value-pair.model';
import { VehicleDetails } from '../models/vehicle-details';
import { NotificationService } from '../services/notification.service';
import { PathFinderService } from '../services/path-finder.service';
import { RoutingService } from '../services/routing-service.service';
import { ToDoService } from '../services/to-do.service';
import { DefaultAnswer } from './models/default-answer.model';
import { Page } from './models/page.model';
import { BaseComponentDirective } from '../base/base.component';
import { State } from './models/state.model';
import { StateResult } from './models/state-result.model';
import { DamageService } from '../services/damage.service';
import { ImageService } from '../services/image.service';
import { ViewportService } from '../services/viewport.service';
import { SharedService } from '../core/shared.service';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { VehicleLookupStatusTypes } from '../enums/vehicle-lookup-status-types';
import { HpiRetryComponent } from '../vehicle-check-hpi/hpi-retry/hpi-retry.component';
import { QuestionFlowData } from './models/question-flow-data.model';
import { NavigationHistoryService } from '../core/navigation-history.service';
import { VehicleListValue } from '../walk-in/models/vehicle-list-value.model';
import { ImageTypes } from '../enums/image-types';
import { REGEX_PARAMETERNAME, Warning, WarningCondition } from './models/warning';
import { LoadingService } from '../services/loading.service';
import { LookupService } from '../services/lookup.service';
import { AuthorizationTypes } from '../authorization/enums/authorization-types';
import { AuthorizationRequest } from '../authorization/models/authorization-request.model';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss'],
})
export class UserInputComponent extends BaseComponentDirective implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  quoteStateId: number;
  stateName: string;
  title: string;
  hasSetInitialFocus: boolean;
  hasLoadedQuestions: boolean;
  questionForm: UntypedFormGroup;
  vrm: string;
  questionPages: Page[];
  isSinglePage: boolean;
  currentPage: number;
  saveNotifier: Subject<number> = new Subject();
  questionSubscription: Subscription;
  previousPages: Array<number> = new Array<number>();
  endProcessSubscription: Subscription;
  buttonsVisible: boolean;
  canEndPurchase: boolean;
  isPurchaseFinalised: boolean;
  questionDefaults: Array<DefaultAnswer>;
  isFinishedLoading = false;
  loadingFromChild = false;
  vehicleDetails: VehicleDetails;
  isStartPurchase: boolean;
  appointmentValuationDetails: AppointmentValuationDetails;
  isNewQuestionAdded: boolean;
  updatedAnswers: Array<Question>;
  undoneAnswers: Array<Question>;
  isPurchaseMenuVisible = true;
  doShowStickyElements = true;
  priceLimit: number;
  offerPrice: number;
  hideChassis: boolean;
  saveErrorMessage: string;
  isBusy = false;
  isPostPurchase = false;
  answersSaved: boolean;
  displayImages: boolean;
  isAllImageAdded: boolean;
  requiredImageType: number;
  capIdChangedOnStart: boolean;
  destroy$ = new Subject();
  state: State;
  changeVrm: string;
  vrmRelated: boolean;
  hasSalvage: boolean;
  canGoBack: boolean;
  isChangeVrmFlow: boolean;
  isVehicleDetailsChangedFlow: boolean;
  isAltVRMFlow: boolean;
  isRolledBack: boolean = false;
  isBookedVrm: string;
  hasColumns: boolean = false;

  constructor(
    private todoService: ToDoService,
    private elementRef: ElementRef,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private notifications: NotificationService,
    private pathFinder: PathFinderService,
    private routingService: RoutingService,
    private appointmentValuationDetailsService: AppointmentValuationDetailsService,
    private damageService: DamageService,
    private imageService: ImageService,
    private cd: ChangeDetectorRef,
    private viewportService: ViewportService,
    private navigationHistory: NavigationHistoryService,
    private sharedService: SharedService,
    private confirmDialogService: ConfirmDialogService,
    private loadingService: LoadingService,
    private lookupService: LookupService,
  ) {
    super();
  }

  @ViewChild('saveButton', { read: ElementRef, static: true }) saveButton: ElementRef;
  @ViewChild('abortButton', { read: ElementRef, static: true }) abortButton: ElementRef;
  @ViewChild('suspendButton', { read: ElementRef, static: true }) suspendButton: ElementRef;
  @ViewChild('appointmentDetailsButton', { read: ElementRef, static: true }) appointmentDetailsButton: ElementRef;
  @ViewChild('cameraInput', { read: ElementRef, static: true }) cameraInput: ElementRef;
  @ViewChild('notMyCarButton', { read: ElementRef, static: true }) notMyCarButton: ElementRef;

  ngAfterViewInit() {
    fromEvent(this.saveButton.nativeElement, 'click')
      .pipe(throttleTime(1000), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        if (
          this.isSinglePage ||
          this.currentPage === this.questionPages.length - 1
        ) {
          this.onSave();
        } else {
          this.onNextPage();
        }
      });
    fromEvent(this.abortButton.nativeElement, 'click')
      .pipe(throttleTime(1000), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        if (this.canEndPurchase) {
          this.onNotPurchase();
        } else {
          this.goToRoute();
        }
      });
      fromEvent(this.suspendButton.nativeElement, 'click')
        .pipe(throttleTime(1000), takeUntil(this.componentDestroyed))
        .subscribe(() => {
          this.onSuspendPurchase();
        });
    fromEvent(this.appointmentDetailsButton.nativeElement, 'click')
      .pipe(throttleTime(1000), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.appointmentValuationDetails.isPurchaseFinalised = this.isPurchaseFinalised;
        this.appointmentValuationDetailsService.showAppointmentDetails(
          this.appointmentValuationDetails
        );
      });
    fromEvent(this.notMyCarButton.nativeElement, 'click')
      .pipe(throttleTime(1000), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.onManualLookup();
      });
  }

  ngOnInit() {
    this.notifications.clearToast();
    this.saveErrorMessage = 'Failed to save answers';
    this.buttonsVisible = true;
    this.getRouteParameters();

    this.imageService.allImagesAdded.subscribe((value) => {
      this.isAllImageAdded = value;
    });

    this.viewportService.getStickyContainersVisibleState().pipe(
      catchError(() => {
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
      .subscribe((result) => {
        this.doShowStickyElements = result;
      });

    this.loadingService.loading.subscribe((loading) => {
      this.loadingFromChild = loading;
    });

    this.sharedService.selectedVehicle.subscribe((response: VehicleListValue) => {
      this.vehicleDetails = new VehicleDetails(
        response.Manufacturer,
        response.Colour,
        response.Model,
        '',
        response.EngineSize,
        response.CapCode.endsWith('L') ? 2 : 1,
        Number(response.CapCodeId),
        response.ImageUrl,
        response.FuelType,
        false,
        null,
      );
    });
  }

  getRouteParameters() {
    this.isBusy = true;
    this.route.data
      .pipe(
        tap((data) => {
          this.isPostPurchase = data.postPurchase || false;
          const id = this.route.snapshot.paramMap.get('id');
          this.init(id);
          this.route.queryParamMap
            .pipe(
              tap((params) => {
                const imageType = params.get('imageType');
                if (this.isPostPurchase) {
                  this.setupPostPurchaseQuestions(imageType);
                } else {
                  this.setupPurchase();
                }
              }),
              takeUntil(this.componentDestroyed)
            )
            .subscribe();
        }),
        takeUntil(this.componentDestroyed)
      )
      .subscribe();
  }

  setupPurchase() {
    this.pathFinder.getPurchaseDetail$(this.quoteStateId).pipe(
      tap((result) => {
        if (result.hpiStatusId === VehicleLookupStatusTypes.TimedOut || result.hpiStatusId === VehicleLookupStatusTypes.Error) {
          const modalRef = this.modalService.open(HpiRetryComponent, {
            keyboard: false,
            backdrop: 'static',
            centered: true,
            size: 'lg',
          });
          modalRef.componentInstance.vrm = result.vrm;
          modalRef.componentInstance.appointmentId = 0;
          modalRef.componentInstance.thingToRetry$ = this.pathFinder.getPurchaseDetail$(this.quoteStateId);
          modalRef.componentInstance.hpiSuccess.subscribe(x => {
            this.actuallySetUpPurchase(x);
          });
        } else {
          this.actuallySetUpPurchase(result);
        }
      }),
      catchError((err) => {
        this.notifications.dangerToast('Failed to retrieve purchase', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  actuallySetUpPurchase(result: QuestionFlowData) {
    this.imageService.removeImagesToDelete();
    this.imageService.removeImagesToSave();
    this.appointmentValuationDetails = result.appointmentValuationDetails;
    this.vrm = result.vrm;

   this.isRolledBack = result.authorizationRequests.length > 0 ? !!result.authorizationRequests.find(x => x.authorizationTypeId === AuthorizationTypes.ChangeVRM ||  x.authorizationTypeId === AuthorizationTypes.AltVRM ||  x.authorizationTypeId === AuthorizationTypes.VehicleDetailsChanged)?.approved : false;
    if (result && !result.authorizationRequests.some(a => a.approved) && !this.isRolledBack) {
      this.privateToOriginalVrm(result);
    }
    this.capIdChangedOnStart = result.capIdChangedOnStart;
    this.hasSalvage = result.vehicleDetails.hasSalvage;
    if (result.vehicleDetails) {
      this.vehicleDetails = result.vehicleDetails;
      this.appointmentValuationDetails.quoteStateId = this.quoteStateId;
      this.isStartPurchase = true;
    }
    this.questionDefaults = result.defaults;
    this.pathFinder.start(this.quoteStateId, result, this.isPostPurchase,
      (state) => {
        if (this.capIdChangedOnStart) {
          this.pathFinder.appendAnswer('CapIdChangedOnStart', 'true');
        }
        this.pathFinder.setJLR(this.vehicleDetails);
        if (state != null) {
          this.loadState(state);
          this.isBusy = false;
          if (this.pathFinder.checkForRiskyCapCheckFailure()) {
            this.notifications.warningToast('DW connection failed: unable to check for risky Cap ID');
          }
          this.registerTransitionEvent();
          setTimeout(() => {
            this.isFinishedLoading = true;
          }, 1000);
        }
      }
    );
  }

  privateToOriginalVrm(result: QuestionFlowData) {
      this.isChangeVrmFlow = Boolean(this.pathFinder.findAnswerValue('ChangeVrm', null, result.answers, false, false));
      this.isVehicleDetailsChangedFlow = Boolean(this.pathFinder.findAnswerValue('VehicleDetailsChanged', null, result.answers, false, false));
      this.isAltVRMFlow = Boolean(this.pathFinder.findAnswerValue('AltVRM', null, result.answers, false, false));
      this.isBookedVrm = this.pathFinder.findAnswerValue('IsBookedVrm', null, result.answers, false, false);
      
      if (this.isBookedVrm === 'true') {
        this.vrm = result.vrm
        this.changeVrm = result.vrm;
        this.isChangeVrmFlow = false;
        this.isAltVRMFlow = false;
      } else if (this.isBookedVrm === 'false') {
        this.vrm = result.vrm;
        this.changeVrm = result.altVrm;
      } else if (this.isChangeVrmFlow) {
        this.vrm = result.vrm;
        this.changeVrm = result.altVrm;
      } else {
        this.vrm = result.vrm;
      }
  }

  registerTransitionEvent() {
    this.pathFinder.registerTransitionEvent((onComplete => {
        onComplete(true);
    }));
  }

  setupPostPurchaseQuestions(imageType) {
    this.todoService.getPostPurchaseQuestions$(this.quoteStateId, imageType).pipe(
      tap((result) => {
        this.pathFinder.setCompletedStates(this.quoteStateId, result.completedStates);
        this.pathFinder.setCompletedAnswers(result.answers);
        this.loadState(this.pathFinder.findState(result.stateName));
        this.isBusy = false;
        setTimeout(() => {
          this.isFinishedLoading = true;
        }, 1000);
      }),
      catchError((err) => {
        this.notifications.dangerToast('Failed to retrieve post purchase questions', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  getAnswer(param: string): string {
    return this.pathFinder.getAnswerValue(param, this.updatedAnswers);
  }

  init(quoteStateId) {
    this.quoteStateId = quoteStateId;
    this.hasSetInitialFocus = false;
    this.hasLoadedQuestions = false;
    this.questionForm = this.formBuilder.group({
      items: this.formBuilder.array([]),
    });
    this.questionPages = [];
    this.currentPage = 0;
  }

  loadState(state: State, lastPage: boolean = false) {
    this.imageService.removeImagesToDelete();
    this.imageService.removeImagesToSave();

    if (state.requiredImages === ImageTypes.ScannedDocument || state.requiredImages === ImageTypes.VehiclePhoto) {
      this.displayImages = true;
    }
    this.stateName = state.name;
    this.state = state;
    this.isSinglePage = state.displayAsSinglePage;
    this.canEndPurchase = state.canEndPurchase;
    this.isPurchaseFinalised = state.isAppraisalFinalised;
    this.requiredImageType = state.requiredImages;
    this.canGoBack = this.canGoBackInPurchase();

    this.questionPages = state.pages;
    this.previousPages = [];
    this.updatedAnswers = [];
    this.undoneAnswers = [];

    this.isPurchaseMenuVisible = true;
    if (this.questionPages && this.questionPages.length > 0) {
      if (this.questionPages[0].questions[0].controlType === ScreenControlTypes.SIGN_INVOICE || this.questionPages[0].questions[0].controlType === ScreenControlTypes.SIGN_D2) {
        this.isPurchaseMenuVisible = false;
      }
    }
    this.currentPage = 0;

    if (!this.isSinglePage && lastPage) {
      for (let i = state.pages.length - 1; i > 0; i--) {
        if (state.pages[i].questions?.some(q => this.pathFinder.getAnswers().some(a => a.parameterName === q.parameterName && a.displayValue))) {
          this.currentPage = i;
          break;
        }
      }
    }
    this.hasColumns = this.isStateMultiColumn();
    this.hasLoadedQuestions = true;
    this.renderQuestionPage();
  }

  isStateMultiColumn(): boolean {
    let multiCol = false;
    if (this.isSinglePage) {
      this.questionPages.forEach(p => {
        if (this.isPageMultiColumn(p)) {
          multiCol = true;
        }
      });
    } else {
      multiCol = this.isPageMultiColumn(this.questionPages[this.currentPage]);
    }
    return multiCol;
  }

  isPageMultiColumn(page: Page): boolean {
    let multiCol = false;
    page.questions.forEach(q => {
      if (q.column) {
        multiCol = true;
      }
    });
    return multiCol;
  }

  renderQuestionPage() {
    this.title = this.questionPages[this.currentPage].title;
    if (!this.title) {
      this.title = this.stateName;
    }

    this.resetFormArray(this.getFormArray());
    this.appendQuestionPage(this.currentPage, this.isSinglePage);
  }

  getFormArray(): UntypedFormArray {
    return this.questionForm.get('items') as UntypedFormArray;
  }

  appendQuestionPage(pageNumber: number, displayHeading: boolean) {
    const valArray = this.getFormArray();
    const validatorFactory = this.createValidatorFactory();
    const firstQuestion = this.questionPages[pageNumber].questions[0].parameterName;

    const questionTransitions = new Array<Question>();
    const questionValues = new Array<string>();
    this.questionPages[pageNumber].questions.forEach((element) => {
      if (element.condition
        && !this.pathFinder.evaluateCondition(element.condition)) {
          if (element.hiddenValue) {
            this.updatedAnswers.push(new Question(element.parameterName, element.hiddenValue));
          }
          return;
      }

      this.setQuestionDefaults(element);

      valArray.push(
        this.formBuilder.group({
          name: element.parameterName,
          value: [
            { value: element.value, disabled: element.disabled },
            validatorFactory(element),
          ],
          question: element.displayQuestion,
          informedAnswer: element.informedAnswer,
          disabled: element.disabled,
          dataType: element.dataType,
          controlType: element.controlType,
          explanation: element.explanation,
          isHidden: element.isHidden,
          condition: element.condition,
          pageNo: pageNumber,
          heading:
            displayHeading && element.parameterName === firstQuestion
              ? this.questionPages[pageNumber].description
              : null,
          minValue: element.minValue,
          maxValue: element.maxValue,
          mask: element.mask,
          formatType: element.formatType,
          keyboardType: element.keyboardType,
          groupIdentifier: `${element.parameterName}.${pageNumber}`,
          column: element.column,
          regEx: element.regEx,
          warning: this.getWarning(element),
          images: [element.images],
          hasManualImageInput: element.hasManualImageInput,
          allowAddImage: element.allowAddImage,
          encrypted: element.encrypted
        })
      );

      if (element.damageOptionKey) {
        this.getDamageOptions(element);
      }
      questionTransitions.push(element);
      questionValues.push(element.value);
    });

    this.checkPageTransitions(questionTransitions, questionValues);
  }

  getWarning(question: Question) {
    let warning: Warning = null;

    if (question.warning) {
      warning = new Warning();
      if (question.warning.condition) {
        const condition = new WarningCondition();
        condition.operator = question.warning.condition.operator;
        condition.value1 = this.updateConditionValue(question, question.warning.condition.value1);
        condition.value2 = this.updateConditionValue(question, question.warning.condition.value2);
        condition.evaluateOnInit = question.warning.condition.evaluateOnInit;
        warning.condition = condition;
      }
      warning.message = question.warning.message;
      warning.warningBorder = question.warning.warningBorder;
    }
    return warning;
  }

  updateConditionValue(question: Question, value: string): string {
    let updatedValue = "";

    switch (value) {
      case '@min':
        updatedValue = question.minValue;
        break;
      case '@max':
        updatedValue = question.maxValue;
        break;
      case '@informedAnswer':
        updatedValue = question.informedAnswer;
        break;
      default:
        const match = value.match(REGEX_PARAMETERNAME);
        if (match != null) {
          updatedValue = this.getAnswer(match[1]);
        } else {
          updatedValue = value;
        }
    }
    return updatedValue;
  }

  checkPageTransitions(questions: Array<Question>, values: Array<string>) {
    for (let i = 0; i < questions.length; i++) {
      this.checkQuestionTransitions(questions[i], values[i], null);
    }
  }

  setQuestionDefaults(question: Question) {
    const defaultValue = question.value; // Default value from PurchaseJourney
    let value = this.getValueFromPreviousAnswers(question.parameterName); // Any previous answer

    // Update element with defaults from HPI
    if (this.questionDefaults) {
      const defaultValues = this.questionDefaults.find(
        (q) => q.parameterName === question.parameterName
      );
      if (defaultValues) {
        question.disabled = question.disabled || defaultValues.disabled;
        value = this.assignPropertiesFromDefaults(
          defaultValues,
          question,
          value
        );
      }
    }

    // If no previous or informed, then use default
    if (value === null) {
      if (question.isHidden && question.hiddenValue) {
        value = question.hiddenValue;
      } else {
        value = defaultValue;
      }
    }

    if (question.parameterName === 'Continue') {
      value = null;
    }
    question.value = value;
  }

  assignPropertiesFromDefaults(
    defaultValues: DefaultAnswer,
    question: Question,
    value: string
  ) {
    if (defaultValues.informedAnswer) {
      // Only update if we don't have a previous answer
      question.informedAnswer = defaultValues.informedAnswer;
      if (!value) {
        value = defaultValues.informedAnswer;
      }
    }
    if (defaultValues.minValue) {
      question.minValue = defaultValues.minValue;
    }
    if (defaultValues.maxValue) {
      question.maxValue = defaultValues.maxValue;
    }
    return value;
  }

  getDamageOptions(question: Question) {
    if (!question?.damageOptionKey) {
      return;
    }

    const keyParts = question.damageOptionKey.split('.');
    const zone = parseInt(keyParts[0], 10);
    const component = parseInt(keyParts[1], 10);

    this.damageService.getDamageMatrix$().pipe(takeUntil(this.componentDestroyed)).subscribe((matrix) => {
      const options = new Array<KeyValuePair>();
      matrix.forEach((item) => {
        if (item.zoneId === zone && item.componentId === component) {
          options.push(
            new KeyValuePair(
              `${item.zoneId}-${item.componentId}-${item.faultId}`,
              item.faultName
            )
          );
        }
      });
      question.options = options;
    });
  }

  resetFormArray(formArray: UntypedFormArray) {
    const len = formArray.controls.length;
    for (let i = 0; i < len; i++) {
      formArray.removeAt(0, { emitEvent: false });
    }
    return formArray;
  }

  getOptions(name: string): KeyValuePair[] {
    return this.findQuestionByName(name).options;
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      if (!this.hasSetInitialFocus && this.hasLoadedQuestions) {
        this.hasSetInitialFocus = true;
        const focusable = this.elementRef.nativeElement.querySelectorAll(
          'input, select, textarea'
        );
        if (focusable.length > 0) {
          focusable[0].focus();
        }
      }
    });
    this.cd.detectChanges();
  }

  saveAnswers(answers): Array<Question> {
    const updatedQuestions = new Array<Question>();

    answers.forEach((answer) => {
      this.questionPages[answer.pageNo].questions.forEach((question) => {
        if (question.parameterName === answer.name) {
          question.value = answer.value;
          const options = this.getOptions(question.parameterName);
          if (options && options.length > 0) {
            const filtered = options.filter((o) => o.key === answer.value);
            if (filtered && filtered.length > 0) {
              question.displayValue = filtered[0].value;
            }
          }
          updatedQuestions.push(question);
        }
      });
    });
    return updatedQuestions;
  }

  onNextPage() {
    this.updatedAnswers.push(
      ...this.saveAnswers(this.questionForm.value.items)
    );

    this.saveNotifier.next(this.quoteStateId);

    if (
      this.questionPages[this.currentPage].transitions != null &&
      this.questionPages[this.currentPage].transitions.length > 0
    ) {
      this.questionPages[this.currentPage].transitions.forEach((transition) => {
        const conditionalQuestions = this.questionPages[
          this.currentPage
        ].questions.filter(
          (q) =>
            q.parameterName === transition.parameterName ||
            transition.parameterName === null
        );

        conditionalQuestions.forEach((cq) => {
          if (
            (cq.parameterName === transition.parameterName &&
              cq.value.toString() === transition.parameterValue.toString()) ||
            transition.parameterName == null
          ) {
            this.previousPages.push(this.currentPage);
            this.currentPage = transition.nextPage - 1;
          }
        });
      });
    } else {
      this.previousPages.push(this.currentPage);
      this.currentPage++;
    }
    this.renderQuestionPage();
  }

  onPreviousPage() {
    if (this.previousPages.length > 0) {
      this.currentPage = this.previousPages.pop();
    } else {
      this.currentPage--;
    }
    this.renderQuestionPage();
  }

  onBackButton() {
    if (this.isBusy) {
      return;
    }

    if (!this.isSinglePage && this.currentPage > 0) {
      this.onPreviousPage();
    } else {
      this.isFinishedLoading = false;
      this.pathFinder.goBackToPreviousState(this.quoteStateId, (state: State) => {
        if (state) {
          this.answersSaved = true;
          this.loadState(state, true);
        }
        this.isFinishedLoading = true;
        this.registerTransitionEvent();
      });
    }
   
    this.isRolledBack = true;
  }

  showNavigationButtons() {
    return this.buttonsVisible && this.isFinishedLoading;
  }

  canGoBackInPurchase() {
    const currentSection = this.pathFinder.getPurchaseSection(this.stateName);
    const previousState = this.pathFinder.getPreviousState();
    if (!previousState || previousState.stateName === '') {
      return false;
    } else {
      return currentSection === previousState.purchaseSection;
    }
  }

  onSave() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;

    this.updatedAnswers.push(
      ...this.saveAnswers(this.questionForm.value.items)
    );
   
    this.saveNotifier.next(this.quoteStateId);

    this.pathFinder.transition(this.quoteStateId, this.state, this.updatedAnswers,
      saveResult => {
        if (saveResult) {
          if (saveResult.returnState) {
            this.loadState(saveResult.returnState);
          } else {
            this.onAnswersSaved(saveResult);
          }
        }
      },
      (nextState, response) => {
        if (response && response.some(a => a.approved) && !this.isRolledBack) {
          this.handleAuthResponse(response);
        }
        this.onStateTransition(nextState);
      },
      () => {
        this.onCancelTransition();
      },
      this.isPostPurchase
    );
  }
  handleAuthResponse(response: AuthorizationRequest[]) {
    if (response.find(x => x.authorizationTypeId === AuthorizationTypes.ChangeVRM)) {
      this.lookupService.updateVrm$(this.quoteStateId, AuthorizationTypes.ChangeVRM).subscribe(() => {
        this.isChangeVrmFlow = null;
        this.vrm = this.changeVrm;
      });
    } else if (response.find(x => x.authorizationTypeId === AuthorizationTypes.AltVRM)) {
      this.lookupService.updateVrm$(this.quoteStateId, AuthorizationTypes.AltVRM).subscribe((response) => {
        this.isAltVRMFlow = null;
        this.vrm = this.changeVrm;
        this.vehicleDetails = response;
      });

    }
  }

  onAnswersSaved(saveResult: StateResult) {
    this.answersSaved = true;
    if (saveResult.valuation) {
      this.pathFinder.setValuationAmount(saveResult.valuation);
    }
  }

  onStateTransition(nextState: State) {
    this.answersSaved = false;
    if (nextState && !this.isPostPurchase) {
      if (nextState.name !== this.stateName) {
        this.loadState(nextState);
      }
      this.isBusy = false;
    } else {
      this.goToRoute();
    }
  }

  private goToRoute() {
    const previousUrl = this.navigationHistory.getPreviousUrl();
    if (previousUrl) {
      this.router.navigate([previousUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }

  onCancelTransition() {
    this.isBusy = false;
  }

  findQuestion(group: UntypedFormGroup) {
    const pageNo = parseInt(group.controls.pageNo.value, 10);
    return this.questionPages[pageNo].questions.find(
      (x) => x.parameterName === group.controls.name.value
    );
  }

  findQuestionByName(parameterName: string) {
    for (let i = 0; i < this.questionPages.length; i++) {
      const question = this.questionPages[i].questions.find(
        (x) => x.parameterName === parameterName
      );
      if (question) {
        return question;
      }
    }
    return null;
  }

  onAnswerChange($event) {
    if (!$event.controls) {
      return;
    }

    const value = $event.controls.value.value;
    // Get the previous value in order to undo any transition relating to it
    const question = this.findQuestion($event);

    const prevValue = question.value;
    question.value = value;
    if ($event.controls.isHidden.value !== question.isHidden) {
      question.isHidden = $event.controls.isHidden.value;
    }
    this.checkQuestionTransitions(question, value, prevValue);

    $event.updateValueAndValidity();
  }

  checkQuestionTransitions(
    question: Question,
    value: string,
    prevValue: string
  ) {
    if (!this.isSinglePage || !question.transitions) {
      return;
    }

    const wasValid = this.isValid(question, prevValue);
    question.transitions
      .filter((x) => this.matches(x, prevValue, wasValid))
      .forEach((transition) => {
        this.undoTransition(transition, this.getFormArray(), question);
      });

    const isValid = this.isValid(question, value);
    if (!question.isHidden) {
      question.transitions
        .filter((x) => this.matches(x, value, isValid))
        .forEach((transition) => {
          this.doTransition(transition, question);
        });
    }
  }

  doTransition(transition: QuestionTransition, question: Question) {
    if (transition.missingDocument) {
      question.missingDocument = transition.missingDocument;
    }
    if (transition.showQuestion) {
      const field = this.findFormGroupByParamName(transition.showQuestion);
      if (field) {
        const childQuestion = this.findQuestion(field);
        childQuestion.isHidden = false;
        field.controls.isHidden.setValue(false);
        if (childQuestion.hiddenValue && field.controls.value.value === childQuestion.hiddenValue) {
          let value = this.pathFinder.getAnswerValue(childQuestion.parameterName, null);
          this.undoneAnswers.forEach((question) => {
            if (question.parameterName === transition.showQuestion) {
              value = question.value;
            }
          });
          if (value && value !== childQuestion.hiddenValue) {
            field.controls.value.setValue(value);
          } else {
            field.controls.value.setValue(null);
          }
        }
        if (childQuestion.transitions) {
          const isValid = this.isValid(childQuestion, field.controls.value.value);
          childQuestion.transitions.filter((x) => this.matches(x, field.controls.value.value, isValid))
            .forEach((transition) => {
              this.doTransition(transition, childQuestion);
            });
        }
        field.controls.value.updateValueAndValidity();
      }
    }
    if (transition.page) {
      this.appendQuestionPage(transition.page - 1, true);
    }
  }

  undoTransition(
    transition: QuestionTransition,
    valArray: UntypedFormArray,
    question: Question
  ) {
    if (transition.missingDocument) {
      question.missingDocument = null;
    }
    if (transition.showQuestion) {
      const field = this.findFormGroupByParamName(transition.showQuestion);
      if (field) {
        const childQuestion = this.findQuestion(field);
        const childValue = field.value.value;
        childQuestion.isHidden = true;
        field.controls.isHidden.setValue(true);
        if (childQuestion.hiddenValue) {
          this.undoneAnswers.push(new Question(childQuestion.parameterName, childValue));
          field.controls.value.setValue(childQuestion.hiddenValue);
        }
        if (childQuestion.controlType === ScreenControlTypes.MULTI_SELECT_LIST_RADIO) {
          childQuestion.options.forEach(option => {
            const optionField = this.findFormGroupByParamName(`${transition.showQuestion}-${option.key}`);
            if (optionField) {
              optionField.controls.value.setValue('0');
            }            
          });
        }
        // If the control has a transition for it's current value then undo that too
        if (childQuestion.transitions) {
          childQuestion.transitions.filter((x) =>
              this.matches(x, childValue, this.isValid(childQuestion, childValue))
            )
            .forEach((ctrlTransition) => {
              this.undoTransition(ctrlTransition, valArray, childQuestion);
            });
        }
        field.controls.value.updateValueAndValidity();
      }
    }
    if (transition.page) {
      // Find all controls from this page and remove them from the array
      const pageIndex = transition.page - 1;
      valArray.controls
        .filter((x) => (x as UntypedFormGroup).controls.pageNo.value === pageIndex)
        .forEach((control) => {
          const ctrlQuestion = this.findQuestion(control as UntypedFormGroup);

          const ctrlValue = control.value.value;
          ctrlQuestion.value = null; // Clear the saved answer for this question

          // If the control has a transition for it's current value then undo that too
          if (ctrlQuestion.transitions) {
            ctrlQuestion.transitions
              .filter((x) =>
                this.matches(
                  x,
                  ctrlValue,
                  this.isValid(ctrlQuestion, ctrlValue)
                )
              )
              .forEach((ctrlTransition) => {
                this.undoTransition(ctrlTransition, valArray, question);
              });
          }

          const delIndex = valArray.controls.indexOf(control);
          if (delIndex !== -1) {
            valArray.controls.splice(delIndex);
          }
        });
    }
  }

  matches(transition: QuestionTransition, value: string, isValid: boolean) {
    if (!transition) {
      return false;
    }
    if (transition.status) {
      if (transition.status.startsWith('#')) {
        return this.pathFinder.getAnswerValueForStringComparison(transition.status.substring(1), this.updatedAnswers) === 'true';
      } else if (value) {
        // Don't transition on a null value
        return isValid;
      }
    } else if (transition.answer && isValid) {
      // Answer transitions are only done on a valid value
      if (transition.answer.startsWith('!')) {
        return value !== transition.answer.substring(1);
      } else {
        return value === transition.answer;
      }
    }
    return false;
  }

  createValidatorFactory() {
    return (question: Question): ValidatorFn =>
      (control: UntypedFormControl): ValidationErrors | null => {
        return this.validate(question, control.value);
      };
  }

  validate(question: Question, value: string): ValidationErrors | null {
    if (question.isHidden) {
      return null;
    }
    if (value !== null) {
      value = value.toString();
    }

    const questionTransition = question.transitions?.find(
      (x) => x.answer === value
    );
    if (questionTransition && questionTransition.status === 'INVALID') {
      return { invalid: true };
    }

    if (question.informedAnswer && value === question.informedAnswer) {
      return null;
    }

    if (question.isRequired && (value === null || value.trim() === '')) {
      return { required: true };
    } else if (!question.isRequired && (value === null || value.trim() === '')) {
      return null;
    }
    // TODO: add isRequiredTrue validation to question so don't need to check parameterName
    if (
      question.isRequired &&
      question.parameterName === 'HPIContinue' &&
      value === 'false'
    ) {
      return { required: true };
    }

    if (question.regEx !== null) {
      const valid = new RegExp(question.regEx).test(value);
      if (!valid) {
        return { invalid: true };
      }
    }
    if (
      question.dataType === 'int' &&
      question.controlType !== ScreenControlTypes.LIST_DROPDOWN
    ) {
      if (isNaN(parseInt(value, 10))) {
        return { invalid: true };
      }
    }
    return null;
  }

  isValid(question: Question, value: string) {
    return this.validate(question, value) === null;
  }

  submitForm(form) {
    if (this.isPostPurchase) {
      this.routingService.returnToPreviousPage();
    } else {
      this.questionForm = form;
      this.onSave();
    }
  }

  setBusy(busy: boolean) {
    this.isBusy = busy;
  }

  findFormGroupByParamName(name: string) {
    return this.getFormArray().controls.find(
      (x) => (x as UntypedFormGroup).controls.name.value === name
    ) as UntypedFormGroup;
  }

  onNotPurchase() {
    const modalRef = this.modalService.open(CancellationPurchaseComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.quoteStateId = this.quoteStateId;
    modalRef.componentInstance.valuationAmount = this.pathFinder.getValuationAmount();
    modalRef.result.then(
      (result) => {
        if (result) {
          this.router.navigate(['/']);
        }
      },
      () => { }
    );
  }

  onSuspendPurchase() {
    this.isBusy = true;
    this.updatedAnswers.push(
      ...this.saveAnswers(this.questionForm.value.items)
    );
    this.pathFinder.submitAnswers(this.quoteStateId, this.state, null, this.updatedAnswers, this.isPostPurchase,
      true, null, (result: StateResult) => {
        this.isBusy = false;
        if (!result.returnState) {
          this.router.navigate(['/']);
        }
      });
  }

  get saveButtonDisabled() {
    if (!this.isFinishedLoading) {
      return true;
    }

    if (this.requiredImageType !== 0) {
      return !this.isAllImageAdded;
    } else {
      return (
        (this.questionPages[this.currentPage].questions.length > 0 &&
          !this.questionForm.valid) ||
        this.isBusy
      );
    }
  }

  get backButtonDisabled() {
    if (!this.isFinishedLoading) {
      return true;
    }

    let disabled: boolean = !this.canGoBack;

    // Allow for post finalise multiple pages
    if (
      !this.isSinglePage &&
      this.questionPages.length > 1 &&
      this.currentPage > 0
    ) {
      disabled = false;
    }

    return disabled;
  }

  get quitButtonDisabled(): boolean {
    if (!this.isFinishedLoading) {
      return true;
    }
    return this.isBusy;
  }

  get isAppraisalFinalised(): boolean {
    return this.isPurchaseFinalised;
  }

  onGoBackInState(stateName: string) {
    this.answersSaved = false;
    this.isBusy = false;
    this.loadState(this.pathFinder.findState(stateName));
  }

  getValueFromPreviousAnswers(parameterName: string): string {
    if (this.pathFinder.getAnswers()) {
      const answer = this.pathFinder.getAnswers().find(element => element.parameterName === parameterName);
      if (answer) {
        return answer.value;
      }
    }
    return null;
  }

async onManualLookup() {
    const confirmManualLookup = await this.confirmDialogService.onManualLookup(this.quoteStateId, this.vrm);
    if (confirmManualLookup) {
      this.updatedAnswers.push(new Question('IsManualLookup', confirmManualLookup.toString()));
      this.updatedAnswers.push(new Question('CapId', this.vehicleDetails.capId.toString()));
      this.updatedAnswers.push(new Question('BookId', this.vehicleDetails.bookId.toString()));
      // this.updatedAnswers.push(new Question('RegYear', this.vehicleDetails.userEnteredFirstRegisteredDate));
      this.capIdChangedOnStart = false;
      this.pathFinder.setJLR(this.vehicleDetails);
    }
  }

  async confirmChangeVRM(onComplete: (value: boolean) => void) {
    if (this.pathFinder.getAuthorizationRequests().some(x => x.authorizationTypeId === AuthorizationTypes.ChangeVRM && x.approved)) {
      onComplete(true);
    } else if (!this.vrmRelated) {
      const confirmChangeVrm = await this.confirmDialogService.onChangeVrm(this.changeVrm);
      if (confirmChangeVrm) {
        this.updatedAnswers.push(new Question('ChangeVrm', confirmChangeVrm.toString()));
        this.pathFinder.endTransitionEvent();
      }
      onComplete(false);
    } else {
      onComplete(true);
    }
  }
}
