import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { BaseInputComponent } from "../base-input/base-input.component";
import moment from 'moment';
import { Subject, takeUntil } from "rxjs";
import { Warning } from "../../user-input/models/warning";

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    }
  ],

  styleUrls: [
    '../input.component.scss'
  ],
})

export class DateInputComponent extends BaseInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() minDate: string;
  @Input() maxDate: string;
  informedAnswer: Date;
  subscriptions = [];
  destroy$ = new Subject();

  showWarning: boolean;
  warningMessage: string;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.minDate) {
      this.minDate = this.minValue;
    }
    if (!this.maxDate) {
      this.maxDate = this.maxValue;
    }
    if (!this.group) {
      this.group = new UntypedFormGroup({
        value: new UntypedFormControl('', [Validators.required, this.dateRangeValidator()])
      });
    }

    this.group.controls.value.setValidators([this.group.controls.value.validator, this.dateRangeValidator()]);
    this.informedAnswer = this.group.value.informedAnswer;
  }

  ngOnDestroy() {
    this.group.controls.value.reset();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  keyPressAlphaNumericWithCharacters(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[0-9/]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  valueChanged() {
    if (this.group.controls.warning) {
      this.evaluateWarning();
    }

    // Format as short ISO date
    const value = this.group.controls.value.value;
    const momentObject = moment(value, 'DD/MM/YYYY', true);
    this.group.controls.value.setValue(momentObject.format('YYYY-MM-DD'));

    this.change.emit(this.group);
  }

  dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (this.informedAnswer && control.value === this.informedAnswer) {
          return null;
        }

        if (this.minDate) {
          if (moment(control.value).isBefore(moment(this.minDate))) {
            return { 'min': { value: control.value } };
          }
        }
        if (this.maxDate) {
          if (moment(control.value).isAfter(moment(this.maxDate))) {
            return { 'max': { value: control.value } };
          }
        }
      }
      return null;
    };
  }

  pickerFilter = (d: moment.Moment | null): boolean => {
    if (this.group) {
      if (this.informedAnswer && d === moment(this.informedAnswer)) {
        return true;
      }

      if (this.minDate) {
        if (moment(d).isBefore(moment(this.minDate))) {
          return false;
        }
      }
      if (this.maxDate) {
        if (moment(d).isAfter(moment(this.maxDate))) {
          return false;
        }
      }
    }
    return true;
  }

  boundPickerFilter = this.pickerFilter.bind(this);

  evaluateWarning() {
    const warning: Warning = this.group.controls.warning.value;

    let showWarning = false;

    if (warning?.condition && this.group.value.value) {
      let value1 = warning.condition.value1;
      if (value1 === '@controlValue') {
        value1 = this.group.value.value;
      }

      switch (warning.condition.operator) {
        case 'less than':
        case '<':
          if (moment(value1).isBefore(moment(warning.condition.value2))) {
            showWarning = true;
          }
          break;
        case 'greater than':
        case '>':
          if (moment(value1).isAfter(moment(warning.condition.value2))) {
            showWarning = true;
          }
          break;
        default:
        // No warning
      }
    }

    if (showWarning) {
      this.showWarning = true;
      this.warningMessage = warning.message;
    } else {
      this.showWarning = false;
      this.warningMessage = '';
    }
  }


  // ControlValueAccessor implementation

  writeValue(obj: string): void {
    this.group.controls.value.setValue(obj);
  }

  registerOnChange(fn: (_: string) => void): void {
    this.subscriptions.push(
      this.group.controls.value.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn)
    );
  }

  onTouch: () => void;
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
