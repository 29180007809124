import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Buyer } from '../models/buyer.model';
import { Site } from '../diary/models/site.model';
import { AuthService } from '../core/auth-service';

@Injectable({
  providedIn: 'root',
})

export class BuyerService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  async getCurrentBuyer(): Promise<Buyer> {
    const buyer = sessionStorage.getItem('current_buyer');
    if (buyer) {
      return JSON.parse(buyer);
    } else {
      const username = this.authService.getUser()?.username.split('@')[0].toLowerCase();
      await this.getBuyerByUsername$(username).toPromise();
      const buyer = sessionStorage.getItem('current_buyer');
      if (buyer) {
        return JSON.parse(buyer);
      } else {
        return undefined;
      }
    }
  }

  getCurrentSiteFromStorage(): Site {
    const site = sessionStorage.getItem('current_site');
    if (site) {
      return JSON.parse(site);
    } else {
      return undefined;
    }
  }

  setCurrentSiteInStorage(site: Site) {
    if (site !== null) {
      sessionStorage.setItem('current_site', JSON.stringify(site));
    } else {
      sessionStorage.removeItem('current_site');
    }
  }

  getBuyer$(employeeId: number): Observable<Buyer> {
    return this.http.get<Buyer>(`${environment.ppxPrivateApi}api/Buyer/${employeeId}`)
      .pipe(
        tap(result => {
          sessionStorage.setItem('current_buyer', JSON.stringify(result));
        }),
      catchError(err => throwError(err))
    );
  }

  getBuyerByUsername$(username: string): Observable<Buyer> {
    return this.http.get<Buyer>(`${environment.ppxPrivateApi}api/Buyer?username=${username}`)
      .pipe(
        tap(result => {
          sessionStorage.setItem('current_buyer', JSON.stringify(result));
        }),
      catchError(err => throwError(err))
    );
  }

}
