import { Component, OnInit, ViewChild, Input, HostListener, AfterViewInit } from '@angular/core';
import { Signature } from '../invoice/models/signature.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Purchase } from '../invoice/models/purchase.model';

@Component({
  selector: 'app-invoice-signature-input',
  templateUrl: './invoice-signature-input.component.html',
  styleUrls: ['./signature-input.component.scss'],
})
export class InvoiceSignatureInputComponent implements OnInit, AfterViewInit {

  @Input() quoteStateId: number;
  @Input() purchaseDetails: Purchase;
  @ViewChild('sigPad') sigPad;
  sigPadElement;
  context;
  isDrawing = false;

  imageFormat = 'image/png';
  hasDrawn: boolean;

  constructor(private modal: NgbActiveModal, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.hasDrawn = false;
  }

  ngAfterViewInit() {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#000000';
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    this.isDrawing = false;
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd() {
    this.isDrawing = false;
  }

  onStartDraw(e) {
    e.preventDefault();
    e.stopPropagation();

    this.hasDrawn = true;
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onDrawMove(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    let clientX = 0;
    let clientY = 0;
    if (window.TouchEvent && event instanceof TouchEvent) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    const bounds = event.target.getBoundingClientRect();
    const x = clientX - bounds.left;
    const y = clientY - bounds.top;
    return { x: x, y: y };
  }

  cleanSignatureBox() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

  clearSignature() {
    this.cleanSignatureBox();
    this.hasDrawn = false;
  }

  acceptSignature() {
    const signaturePng = this.sigPadElement.toDataURL(this.imageFormat);
    const blob = this.dataURLtoBlob(signaturePng);

    this.read(blob, (result) => {
      const signatureData = new Signature();
      signatureData.filename = `customer-${this.quoteStateId}`;
      signatureData.type = this.imageFormat;
      signatureData.isWbacSignature = false;
      signatureData.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
      signatureData.imageData = result;

      this.cleanSignatureBox();
      this.modal.close(signatureData);
    });
  }

  dismissModal() {
    this.modal.close();
  }

  read(blob, callback) {
    const reader = new FileReader();
    reader.onload = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(blob);
  }

  dataURLtoBlob(dataURL) {
    const binary = atob(dataURL.split(',')[1]);
    // Create 8-bit unsigned array
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    // Return our Blob object
    return new Blob([new Uint8Array(array)], { type: this.imageFormat });
  }

  hasCustomerPayment(): boolean {
    if (!this.purchaseDetails?.valuation) {
      return false;
    }

    return this.purchaseDetails.valuation.customerAmount > 0;
  }  
}
