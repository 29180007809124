<div class="modal-header">
  <img class="modal-close" (click)="dismissModal()" alt="">
</div>
<div class="modal-body">
  <canvas #sigPad width="1048" height="400" (mousedown)="onStartDraw($event)" (touchstart)="onStartDraw($event)"
    (mousemove)="onDrawMove($event)" (touchmove)="onDrawMove($event)" class="signature-box"></canvas>
  <div class="summary-details-text">
    <span class="red-text">PLEASE CHECK: </span>
    <span *ngIf="hasCustomerPayment()">
      <span class="green-text">{{ purchaseDetails.valuation.customerAmount | currency:'GBP' }}</span> to account <span class="green-text">{{ purchaseDetails.customer.accountNumber }}</span>
      sort code <span class="green-text">{{ purchaseDetails.customer.sortCode }}, {{ purchaseDetails.customer.accountName }}</span><br>
    </span>
    Invoice will be sent to <span class="green-text">{{ purchaseDetails.customer.emailAddress }}</span><br>
    I confirm I removed personal data from devices in the vehicle, and maintenance records.<br>
    I confirm the details on the invoice are correct and agree to the terms and condition of sale.
  </div>
  <span class="signature-line"></span>
<div class="button-panel">
  <button class="btn signature-btn button_clear" (click)="clearSignature()">Clear
    <img src="../../assets/icons/buttons/cancel-grey.png" alt="">
  </button>
  <button class="btn signature-btn button_clear" (click)="dismissModal()">View Invoice and Ts & Cs
    <img src="../../assets/icons/buttons/binoculars.png" alt="">
  </button>
  <button class="btn signature-btn button_green" [disabled]="!hasDrawn" (click)="acceptSignature()" ngbAutofocus>
    Confirm all details above are correct
    <img *ngIf="hasDrawn" src="../../assets/icons/buttons/save-white.png" alt="">
    <img *ngIf="!hasDrawn" src="../../assets/icons/buttons/save-70pc.png" alt="">
  </button>
</div>
