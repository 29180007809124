<div class="mileage">
  <div class="logo-container">
    <img class="provider-logo" src="{{ providerLogo }}" />
  </div>
  <div class="recorded-mileage"
    [ngClass]="{ 'recorded-mileage--invalid' : hasDiscrepancy, 'recorded-mileage--no-record' : mileageData?.length === 0 }">
    Last recorded mileage:
    <span *ngIf="mileageData?.length > 0">{{ mileageData[0].mileageReading | number: '1.0' }}</span>
  </div>
  <button class="dropdown"
    [ngClass]="{ 'dropdown--expanded' : showMotHistory, 'dropdown--discrepancy' : hasDiscrepancy, 'dropdown--no-record' : mileageData?.length === 0 }"
    (click)="showMotHistory = !showMotHistory" [disabled]="mileageData?.length === 0">
    <div *ngIf="hasDiscrepancy" class="dropdown__warning"></div>
    <div class="dropdown__title"
      [ngClass]="{ 'dropdown__title--discrepancy' : hasDiscrepancy, 'dropdown__title--no-record': mileageData?.length === 0 }">
      {{ dropdownTitle }}</div>
    <div class="dropdown__icon"
      [ngClass]="{ 'dropdown__icon--invalid' : hasDiscrepancy, 'dropdown__icon--open' : showMotHistory, 'dropdown__icon--disabled' : mileageData?.length === 0 }">
    </div>
  </button>
  <div *ngIf="mileageData?.length > 0 && showMotHistory" class="mot-history"
    [ngClass]="{ 'mot-history--discrepancy' : hasDiscrepancy }">
    <div class="d-none d-lg-block">
      <table>
          <tr class="mileage-data-row">
            <th class="text-center">Mileage Date</th>
            <th class="text-center">Mileage Reading</th>
            <th class="text-center"><span *ngIf="provider === 'dvsa'">MOT Test Outcome</span></th>
          </tr>
          <tr *ngFor="let mileage of mileageData" class="mileage-data-row" [ngClass]="{ 'mileage-discrepancy' :  mileage.hasDiscrepancy }">
            <td>{{ mileage.mileageDate | date: 'shortDate' }}</td>
            <td class="text-center">{{ mileage.mileageReading | number: '1.0' }}</td>
            <td class="text-center">{{ mileage.motTestOutcome }}</td>
          </tr>
      </table>
    </div>

    <div class="d-block d-lg-none">
      <table>
        <tr class="mileage-data-row">
          <th>Mileage Date</th>
          <th>Mileage Reading</th>
          <th><span *ngIf="provider === 'dvsa'">MOT Test Outcome</span></th>
        </tr>
        <tr *ngFor="let mileage of mileageData" class="mileage-data-row" [ngClass]="{ 'mileage-discrepancy' :  mileage.hasDiscrepancy }">
          <td>{{ mileage.mileageDate | date: 'shortDate' }}</td>
          <td>{{ mileage.mileageReading | number: '1.0' }}</td>
          <td>{{ mileage.motTestOutcome }}</td>
        </tr>
      </table>
    </div>

  </div>
</div>