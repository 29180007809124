export class Warning {
  condition: WarningCondition;
  message: string;
  warningBorder: boolean;
}

// Use string replacement to insert parameter values
// These will be replaced in the user component so that individual components
// don't need to know about all the answers/question definition
//    {{parameterName}} to insert values from answers
//    @min, @max, @informedAnswer for values from question
export class WarningCondition {
  value1: string;
  operator: string;
  value2: string;
  evaluateOnInit: boolean;
}

export const REGEX_PARAMETERNAME = /{{(.*?)}}/;
