import { Component, OnInit, ChangeDetectorRef, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { VehicleCheckService } from '../../services/vehicle-check.service';
import { NotificationService } from '../../services/notification.service';
import moment from 'moment';
import { Subject, Observable, EMPTY, fromEvent, concat } from 'rxjs';
import { ServiceRecord } from '../models/service-record.model';
import { ServiceHistoryInWarrantyCheckRequest } from '../models/service-history-in-warranty-check-request.model';
import { catchError, tap, takeUntil, throttleTime, debounceTime } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base.component';
import { ServiceHistoryService } from '../../services/service-history.service';
import { PathFinderService } from '../../services/path-finder.service';
import { VehicleCheck } from '../../vehicle-check-hpi/models/vehicle-check.model';

@Component({
  selector: 'app-service-interval-check',
  templateUrl: './service-interval-check.component.html',
  styleUrls: ['../../inputs/input.component.scss',
    './service-interval-check.component.scss']
})
export class ServiceIntervalCheckComponent extends BaseComponentDirective implements OnInit, AfterViewInit {

  @Input() quoteStateId: number;
  @Input() vrm: string;
  @Input() group: UntypedFormGroup;
  @Input() save: Subject<number>;

  @ViewChild('retryButton', { read: ElementRef, static: true }) retryButton: ElementRef;

  firstRegisteredOrManufacturedDate: Date;
  ageOfVehicleInMonths: number;
  copyLastService = false;
  serviceCounter = 0;
  isValid = false;
  selectedType: string;
  today = new Date();
  getVehicleCheck$: Observable<VehicleCheck>;
  getServiceHistory$: Observable<ServiceHistoryInWarrantyCheckRequest>;
  showRetryButton = false;
  isStillInWarranty = true;
  percentComplete = 0;
  mileage: number;

  leniency = 1.075; // 7.5%

  servicesFormGroup: UntypedFormGroup;
  serviceHistoryInWarrantyForm = new UntypedFormGroup({
    lastServiceDate: new UntypedFormControl(null, [Validators.required])
  });

  serviceHistoryData: ServiceHistoryInWarrantyCheckRequest;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private vehicleCheckService: VehicleCheckService,
    private serviceHistoryService: ServiceHistoryService,
    private notifications: NotificationService,
    private cdr: ChangeDetectorRef,
    private pathFinder: PathFinderService) {
    super();
  }

  ngOnInit() {
    const mileageAnswer = this.pathFinder.getAnswerValue('Mileage', null);
    if (mileageAnswer) {
        this.mileage = parseInt(mileageAnswer, 10);
    }

    this.servicesFormGroup = this.formBuilder.group({
      name: 'services',
      services: this.formBuilder.array([])
    });

    this.serviceHistoryInWarrantyForm.valueChanges.pipe(
      tap(() => {
        this.checkIsValid();
      }),
      takeUntil(this.componentDestroyed))
      .subscribe();

    this.createVehicleCheckObservable();
    this.createServiceHistoryObservable();

    this.save.pipe(
      takeUntil(this.componentDestroyed)
    ).subscribe(() => {
      this.updateServiceHistoryData(this.serviceHistoryData);
      this.serviceHistoryService.saveInWarrantyServiceHistory$(this.serviceHistoryData)
      .subscribe();
    });

    const result = concat(this.getVehicleCheck$, this.getServiceHistory$);
    result.subscribe();

    setTimeout(() => {
      this.percentComplete = 0;
      this.checkIsValid();
    });
  }

  ngAfterViewInit() {
    fromEvent(this.retryButton.nativeElement, 'click').pipe(
      throttleTime(3000),
      takeUntil(this.componentDestroyed)
    ).subscribe(() => {
      this.retryButtonPress.next(true);
      this.createVehicleCheckObservable();
      this.createServiceHistoryObservable();
    });
  }

  createVehicleCheckObservable() {
    this.getVehicleCheck$ = this.vehicleCheckService.getVehicleCheck$(this.vrm, this.quoteStateId, true).pipe(
      tap(result => {
        this.firstRegisteredOrManufacturedDate = result.vehicles[0].imported ? result.calculatedManufacturedDate : result.vehicles[0].ukDateOfFirstRegistration;
        this.ageOfVehicleInMonths = this.differenceInMonths(moment(), moment(this.firstRegisteredOrManufacturedDate));
        this.showRetryButton = false;
      }),
      catchError(err => {
        this.showRetryButton = true;
        this.notifications.dangerToast('Failed to retrieve vehicle check, please try again.', err);
        return EMPTY;
      }),
      takeUntil(this.retryButtonPressedOrComponentDestroyed$));
  }

  createServiceHistoryObservable() {
    this.getServiceHistory$ = this.serviceHistoryService.getInWarrantyServiceHistory$(this.quoteStateId).pipe(
      tap(result => {
        this.serviceHistoryData = result;
        if (this.serviceHistoryData === null) {
          this.serviceHistoryData = this.createExpectedServiceHistorySchedule();
        }
        this.createServiceHistoryControls();

        this.servicesFormGroup.controls.services.valueChanges.pipe(
          debounceTime(500),
          tap(() => {
            this.calculateServiceIntervals();
          }),
          takeUntil(this.componentDestroyed))
          .subscribe();
      }),
      catchError(err => {
        this.showRetryButton = true;
        this.notifications.dangerToast('Failed to retrieve vehicle check, please try again.', err);
        return EMPTY;
      }),
      takeUntil(this.retryButtonPressedOrComponentDestroyed$));
  }

  createServiceHistoryControls() {
    for (const serviceRecord of this.serviceHistoryData.services) {
      this.addServiceRow(serviceRecord);
    }
  }

  updateServiceHistoryData(serviceHistoryData: ServiceHistoryInWarrantyCheckRequest) {
    serviceHistoryData.lastServiceDate = this.serviceHistoryInWarrantyForm.controls.lastServiceDate.value;
    serviceHistoryData.ageOfVehicleInMonths = this.ageOfVehicleInMonths;
    serviceHistoryData.quoteStateId = this.quoteStateId;
    serviceHistoryData.lastServiceMonthInterval = this.differenceInMonths(moment(), moment(this.serviceHistoryInWarrantyForm.controls.lastServiceDate.value));
    serviceHistoryData.services = this.getServiceRecords();
  }

  getServiceRecords(): Array<ServiceRecord> {
    const serviceRows = this.servicesFormGroup.get('services') as UntypedFormArray;
    const serviceList = new Array<ServiceRecord>();

    for (let i = 0; i < serviceRows.value.length; i++) {
      const serviceRow = serviceRows.value[i];
      const service = new ServiceRecord();

      service.rowIndex = i;
      service.serviceDate = new Date(serviceRow.serviceDate);
      service.mileage = parseFloat(serviceRow.serviceMileage);
      service.monthInterval = serviceRow.monthInterval ?? 0;
      service.mileageInterval = serviceRow.mileageInterval;

      serviceList.push(service);
    }

    return serviceList;
  }

  calculateServiceIntervals() {
    this.copyLastService = false;
    
    const serviceRows = this.services.value;

    const sortedServices = this.sortServicesByDate(this.getServiceRecords());

    let incompleteServices = 0;
    let completeServices = 0;

    this.isStillInWarranty = true;

    let previousServiceDate = this.firstRegisteredOrManufacturedDate;
    let previousMileage = 0;

    for (let i = 0; i < sortedServices.length; i++) {

      const rowIndex = sortedServices[i].rowIndex;

      if (!sortedServices[i].mileage || !sortedServices[i].serviceDate) {
        serviceRows[rowIndex].monthInterval = null;
        serviceRows[rowIndex].mileageInterval = null;
        incompleteServices++;
        continue;
      }

      const mileageInterval = sortedServices[i].mileage - previousMileage || 0;
      const monthInterval = this.differenceInMonths(moment(sortedServices[i].serviceDate), moment(previousServiceDate)) || 0;

      previousMileage = sortedServices[i].mileage;
      previousServiceDate = sortedServices[i].serviceDate;

      // Check this is not a duplicate service record
      if (i > 0
        && (sortedServices[i].mileage !== sortedServices[i - 1].mileage
          || sortedServices[i].serviceDate.valueOf() !== sortedServices[i - 1].serviceDate.valueOf())) {
        completeServices++;
      }

      (this.services.controls[rowIndex] as UntypedFormGroup).controls.mileageInterval.setValue(mileageInterval, { emitEvent: false });
      (this.services.controls[rowIndex] as UntypedFormGroup).controls.monthInterval.setValue(monthInterval, { emitEvent: false });

      if (this.isMileageExceeded(mileageInterval) || this.isMonthsExceeded(monthInterval)) {
        this.isStillInWarranty = false;
      }

      if (mileageInterval < 0) {
        (this.services.controls[rowIndex] as UntypedFormGroup).controls.serviceMileage.setErrors({ 'invalid': true });
      } else {
        (this.services.controls[rowIndex] as UntypedFormGroup).controls.serviceMileage.setErrors(null);
      }

      // As they're now sorted, just update last service date
      this.serviceHistoryInWarrantyForm.controls.lastServiceDate.setValue(sortedServices[i].serviceDate);
    }

    const lastServiceMileage = sortedServices[sortedServices.length - 1].mileage;
    const lastServiceDate = sortedServices[sortedServices.length - 1].serviceDate;
    const lastServiceMonths = this.differenceInMonths(moment(lastServiceDate), moment(this.firstRegisteredOrManufacturedDate));

    if (lastServiceMileage && lastServiceDate) {
      const servicesRequiredSoFarByMileage = Math.trunc(lastServiceMileage / this.serviceHistoryData.recommendedMileage);
      const servicesRequiredSoFarByInterval = Math.trunc(lastServiceMonths / this.serviceHistoryData.recommendedMonths);
      const servicesRequiredSoFar = Math.max(completeServices, servicesRequiredSoFarByMileage, servicesRequiredSoFarByInterval);

      // Check how long since last service date and mileage
      const mileageInterval = Math.max(this.mileage - lastServiceMileage, 0);
      const monthInterval = Math.max(this.differenceInMonths(moment(), lastServiceDate), 0);

      const servicesStillRequiredByMileage = Math.trunc(mileageInterval / this.serviceHistoryData.recommendedMileage);
      const servicesStillRequiredByInterval = Math.trunc(monthInterval / this.serviceHistoryData.recommendedMonths);
      const servicesStillRequired = Math.max(servicesStillRequiredByMileage, servicesStillRequiredByInterval);

      if (incompleteServices === 0) {
        if (this.isMileageExceeded(mileageInterval) || this.isMonthsExceeded(monthInterval)) {
          this.isStillInWarranty = false;
        }
      }

      this.percentComplete = completeServices * 100 / (servicesRequiredSoFar + servicesStillRequired);
    }

    this.checkIsValid();
  }

  sortServicesByDate(array: ServiceRecord[]): ServiceRecord[] {
    if (array !== undefined) {
      return array.sort((a: ServiceRecord, b: ServiceRecord) => {

        const aValue = a.serviceDate ? new Date(a.serviceDate) : undefined;
        const bValue = b.serviceDate ? new Date(b.serviceDate) : undefined;

        if (aValue === bValue) {
          return 0;
        } else if (aValue && !bValue || aValue < bValue) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    return array;
  }

  differenceInMonths(laterDate, earlierDate) {
    return laterDate.diff(earlierDate, 'months');
  }

  get services() {
    return this.servicesFormGroup.get('services') as UntypedFormArray;
  }

  createService(serviceRecord: ServiceRecord) {
    this.serviceCounter++;

    const service = this.formBuilder.group({
      serviceNumber: new UntypedFormControl(this.serviceCounter),
      serviceDate: new UntypedFormControl(serviceRecord.serviceDate, [Validators.required]),
      serviceMileage: new UntypedFormControl(serviceRecord.mileage, [Validators.required, Validators.min(0)]),
      mileageInterval: new UntypedFormControl(serviceRecord.mileageInterval),
      monthInterval: new UntypedFormControl(serviceRecord.monthInterval)
    });

    return service;
  }

  addServiceRow(serviceRecord?: ServiceRecord) {
    if (!serviceRecord) {
      serviceRecord = new ServiceRecord();
      this.serviceHistoryData.services.push(serviceRecord);
    }
    setTimeout(() => {
      this.serviceHistoryInWarrantyForm.controls.lastServiceDate.reset();
      const newRow = this.createService(serviceRecord);
      this.services.push(newRow);
      this.cdr.detectChanges();
      this.checkIsValid();
    });
  }

  removeServiceRow(index: number) {
    this.services.removeAt(index);
    this.cdr.detectChanges();
    this.checkIsValid();
  }

  checkIsValid() {
    if (this.serviceHistoryInWarrantyForm.controls.lastServiceDate.value != null && this.services.valid) {
      this.isValid = true;
      this.group.controls.value.setValue(this.isStillInWarranty);
      return;
    }
    this.isValid = false;
    this.group.controls.value.reset();
  }

  isRowMonthsExceeded(rowIndex: number): boolean {
    let numValue = 0;
    const monthIntervalControl = (this.services.controls[rowIndex] as UntypedFormGroup).controls.monthInterval;
    if (monthIntervalControl) {
      numValue = Number(monthIntervalControl.value);
    }
    return this.isMonthsExceeded(numValue);
  }

  isMonthsExceeded(monthInterval: number): boolean {
    return monthInterval > this.serviceHistoryData.recommendedMonths * this.leniency;
  }

  isRowMileageExceeded(rowIndex: number): boolean {
    let numValue = 0;
    const mileageIntervalControl = (this.services.controls[rowIndex] as UntypedFormGroup).controls.mileageInterval;
    if (mileageIntervalControl) {
      numValue = Number(mileageIntervalControl.value);
    }
    return this.isMileageExceeded(numValue);
  }

  isMileageExceeded(mileageInterval: number): boolean {
    return mileageInterval > this.serviceHistoryData.recommendedMileage * this.leniency;
  }

  createExpectedServiceHistorySchedule(): ServiceHistoryInWarrantyCheckRequest {
    const history = new ServiceHistoryInWarrantyCheckRequest();

    history.quoteStateId = this.quoteStateId;
    history.ageOfVehicleInMonths = this.ageOfVehicleInMonths;
    history.mileage = this.mileage;
    history.lastServiceDate = this.serviceHistoryInWarrantyForm.controls.lastServiceDate.value;
    history.lastServiceMonthInterval = this.differenceInMonths(moment(), moment(this.serviceHistoryInWarrantyForm.controls.lastServiceDate.value));
    history.recommendedMileage = this.getPreviousAnswerAsNumber('MileageBetweenServices');
    history.recommendedMonths = this.getPreviousAnswerAsNumber('MonthsBetweenServices');

    history.services = new Array<ServiceRecord>();

    const expectedServicesByDate = Math.trunc(history.ageOfVehicleInMonths / history.recommendedMonths);
    const expectedServicesByMileage = Math.trunc(history.mileage / history.recommendedMileage);

    const customerAdvisedServiceRecords = this.getTotalCustomerServiceRecords();

    const expectedServices = Math.max(expectedServicesByDate, expectedServicesByMileage, customerAdvisedServiceRecords);

    for (let i = 0; i < expectedServices; i++) {
      const serviceRecord = new ServiceRecord();
      history.services.push(serviceRecord);
    }

    return history;
  }

  getTotalCustomerServiceRecords(): number {
    let totalRecords = 0;

    let recordsByType = this.getPreviousAnswerAsNumber('ElectronicServiceHistory');
    if (recordsByType !== null) {
      totalRecords += recordsByType;
    }

    recordsByType = this.getPreviousAnswerAsNumber('ServiceBook');
    if (recordsByType !== null) {
      totalRecords += recordsByType;
    }

    recordsByType = this.getPreviousAnswerAsNumber('ServiceInvoice');
    if (recordsByType !== null) {
      totalRecords += recordsByType;
    }
    return totalRecords;
  }

  getPreviousAnswerAsNumber(parameterName: string): number {
    const answer = this.pathFinder.getAnswerValue(parameterName, null);
    if (answer !== '') {
      const numberValue = Number(answer);
      if (!isNaN(numberValue)) {
        return numberValue;
      }
    }
    return null;
  }
}
