import { Component, Input, OnInit } from '@angular/core';
import { MileageService } from '../services/mileage.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MileageCheckResponse } from '../mileage/models/mileage-check-response.model';
import { Observable, EMPTY, Subject, forkJoin } from 'rxjs';
import { tap, catchError, takeUntil, take } from 'rxjs/operators';
import { BaseComponentDirective } from '../base/base.component';
import { NotificationService } from '../services/notification.service';
import { MileageRecord } from '../mileage/models/mileage-record.model';
import { MotCheckResponse } from '../mileage/models/mot-check-response.model';
import { KeyValuePair } from '../models/key-value-pair.model';

@Component({
  selector: 'app-mileage-display',
  templateUrl: './mileage-display.component.html',
  styleUrls: ['./mileage-display.component.scss']
})

export class MileageDisplayComponent extends BaseComponentDirective implements OnInit {

  @Input() vrm: string;
  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Input() questionForm: UntypedFormGroup;
  @Input() mileage: number;
  @Input() mileageDecision: string;
  @Input() isFromAuthModal: boolean;
  discrepancyQuestion: UntypedFormGroup;

  calculatedDecision: string;

  hasDvsaDiscrepancy: boolean;
  hasNmrDiscrepancy: boolean;
  hasBcaDiscrepancy: boolean;
  hasMileageDiscrepancy: boolean;

  mileageComplete = new Subject<boolean>();
  motcomplete = new Subject<boolean>();

  complete$: Observable<[boolean, boolean]>;
  motCheck$: Observable<MotCheckResponse>;
  mileageCheck$: Observable<MileageCheckResponse>;

  nmrMileage: Array<MileageRecord>;
  bcaMileage: Array<MileageRecord>;
  dvsaMileage: Array<MileageRecord>;

  showDvsaHistory: boolean;
  showNmrHistory: boolean;
  showBcaHistory: boolean;

  mileageForm: UntypedFormGroup;
  mileageDecisionControl: UntypedFormGroup;

  allowedDecisionOptions: KeyValuePair[];

  constructor(private mileageService: MileageService, private notifications: NotificationService, private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {

    this.allowedDecisionOptions = [
      { key: '1', value: 'Mileage History Clear' },
      { key: '205', value: 'DVSA Clerical Error' },
      { key: '204', value: 'DVSA Mileage Discrepancy' },
    ];

    this.mileageForm = this.formBuilder.group({
      mileage: [this.mileage]
    });

    this.mileageDecisionControl = this.formBuilder.group({
      name: 'mileageDecision',
      value: this.mileageDecision,
      question: '',
      explanation: '',
      informedAnswer: '',
      formatType: '',
      mask: '',
      disabled: false,
      minValue: null,
      maxValue: null,
      readOnly: true
    });

    if (this.questionForm) {
      this.discrepancyQuestion = this.getDiscrepancyQuestion();
    }
    this.createMileageAndMothistoryObservables();
    this.motCheck$.pipe(takeUntil(this.componentDestroyed)).subscribe();
    this.mileageCheck$.pipe(takeUntil(this.componentDestroyed)).subscribe();
    this.complete$
      .pipe(take(1))
      .subscribe(() => {
        if (this.questionForm) {
          this.discrepancyQuestion.controls.value.setValue(
            this.hasAnyMileageDiscrepancy()
          );
        } else {
          this.hasAnyMileageDiscrepancy();
        }
      });
  }

  getDiscrepancyQuestion(): UntypedFormGroup {
    return this.questionForm.get('items')['controls'].filter(x => x.value.name === 'HasMileageDiscrepancy')[0];
  }

  createMileageAndMothistoryObservables() {
    this.mileageComplete = new Subject<boolean>();
    this.motcomplete = new Subject<boolean>();
    this.complete$ = forkJoin([
      this.mileageComplete,
      this.motcomplete
    ]);

    this.mileageCheck$ = this.mileageService.mileageCheck$(this.quoteStateId).pipe(
      tap(result => {
        this.nmrMileage = result.nmrMileageRecords;
        this.bcaMileage = result.bcaMileageRecords;
        this.mileageComplete.next(true);
        this.mileageComplete.complete();
      }),
      catchError(err => {
        if (this.group) {
          this.group.controls.value.setValue(true);
        }
        this.notifications.dangerToast('There was a problem finding mileage history for this vehicle.', err);
        this.mileageComplete.next(true);
        this.mileageComplete.complete();
        return EMPTY;
      }),
      takeUntil(this.retryButtonPressedOrComponentDestroyed$)
    );

    this.motCheck$ = this.mileageService.doMotHistoryCheck$(this.quoteStateId).pipe(
      tap(result => {
        this.dvsaMileage = result.dvlaMileageRecords;
        this.motcomplete.next(true);
        this.motcomplete.complete();
      }),
      catchError(err => {
        if (this.group) {
          this.group.controls.value.setValue(true);
        }
        this.notifications.dangerToast('There was a problem finding DVSA mileage for this vehicle. Click Try Again to retry.', err);
        this.motcomplete.next(true);
        this.motcomplete.complete();
        return EMPTY;
      }),
      takeUntil(this.retryButtonPressedOrComponentDestroyed$)
    );
  }

  hasAnyMileageDiscrepancy(): boolean {
    let hasAnyMileageDiscrepancy = false;
    this.hasNmrDiscrepancy = false;
    this.hasDvsaDiscrepancy = false;
    this.hasBcaDiscrepancy = false;

    this.calculatedDecision = '1';

    if (this.nmrMileage && this.nmrMileage.length > 0) {
      const latestMileage = this.nmrMileage[0].mileageReading;
      if (this.nmrMileage.filter(nmr => nmr.hasDiscrepancy).length > 0 || this.mileage < latestMileage) {
        this.hasNmrDiscrepancy = true;
        this.showNmrHistory = true;
      }
    }

    if (this.dvsaMileage && this.dvsaMileage.length > 0) {
      const latestMileage = this.dvsaMileage[0].mileageReading;
      if (this.dvsaMileage.filter(dvsa => dvsa.hasDiscrepancy).length > 0 || this.mileage < latestMileage) {
        this.hasDvsaDiscrepancy = true;
        this.showDvsaHistory = true;
        this.calculatedDecision = '204';
      }
    }

    if (this.bcaMileage && this.bcaMileage.length > 0) {
      const latestMileage = this.bcaMileage[0].mileageReading;
      if (this.bcaMileage.filter(bca => bca.hasDiscrepancy).length > 0 || this.mileage < latestMileage) {
        this.hasBcaDiscrepancy = true;
        this.showBcaHistory = true;
      }
    }

    hasAnyMileageDiscrepancy = this.hasNmrDiscrepancy || this.hasDvsaDiscrepancy || this.hasBcaDiscrepancy;

    this.mileageDecisionControl.controls.informedAnswer.setValue(this.calculatedDecision);

    return hasAnyMileageDiscrepancy;
  }

  // prevents input from adding characters on tablets after max limit is reached
  // (very specific scenario, might be caused by a bug)
  preventInputOnTablet(inputMileage: string) {
    const inputLength = inputMileage.length;
    const isMaxInput = inputLength >= 8;
    if (isMaxInput) {
      inputMileage = inputMileage.slice(0, -1);
      this.mileageForm.patchValue({
        mileage: inputMileage
      });
    }
  }
}
